export default {
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếng Anh"])},
  "Vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếng Việt"])},
  "Order Online Call Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gọi Ngay"])},
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang chủ"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giới thiệu"])},
    "Vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếng Việt"])},
    "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếng Anh"])}
  },
  "Art Classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lớp Mỹ Thuật"])},
  "Find our Mandala classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm lớp học phù hợp với bạn nhất tại Mandala House"])},
  "Art Gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phòng Trưng Bày Nghệ Thuật"])},
  "Find our Mandala galleries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm phòng trưng bày bạn muốn của Mandala House"])},
  "Items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mặt hàng"])},
  "Find Great Items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm món hàng bạn thích tại Mandala House"])},
  "From our blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài viết"])},
  "all my posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tất cả bài viết"])},
  "view all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xem thêm"])},
  "All Rights Reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bảo lưu bản quyền"])},
  "FREE SHIPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIẾN PHÍ GIAO HÀNG"])},
  "On all orders over $75.00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả đơn hàng trên 75 đôla"])},
  "FREE RETURNS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoàn Trả Miễn Phí"])},
  "30 days money back guarantee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đảm bảo hoàn tiền trong 30 ngày"])},
  "SECURE PAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THANH TOÁN AN TOÀN"])},
  "read more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đọc thêm"])},
  "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lùi"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kế"])},
  "Blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bài viết"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang chủ"])},
  "Class List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lớp học"])},
  "More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm nữa ..."])},
  "Place Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt hàng"])},
  "Pick up at Store or in your door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lấy hàng tại cửa hàng hoặc nhận hàng tại nhà"])},
  "Video list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh Sách Video"])},
  "Fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Họ và tên"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điện thoại"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu ý"])},
  "Pay now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanh toán"])},
  "Shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cửa hàng"])},
  "Online Gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem trực tuyến"])},
  "Numerology loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numerology loading"])},
  "Numerology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thần số học"])}
}
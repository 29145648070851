import session from './session';
import { apiUrl } from './index';

export default {
	getMiscData : function (params) { return session.get(apiUrl + '/misc-data/', { params }) },
  	getItems: function (params) { return session.get(apiUrl + '/items/', { params }) },
  	getPosts: function (params) { return session.get(apiUrl + '/posts/', {params}) },
  	getClasses: function (params) { return session.get(apiUrl + '/classes/',{params}) },
  	getCourses: function (params) { return session.get(apiUrl + '/courses/',{params}) },
  	getGalleries: function (params) { return session.get(apiUrl + '/albums/',{params}) },
  	getSliders: function (params) { return session.get(apiUrl + '/sliders/',{params}) },
  	getBanners: function (params) { return session.get(apiUrl + '/banners/',{params}) },
  	createTask: function (form_data) { return session.post(apiUrl + '/task/', form_data) },
}

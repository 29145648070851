import { createApp } from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import axios from 'axios'
//import ElementPlus from 'element-plus'
//import 'element-plus/dist/index.css'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import i18n from './i18n'
import moment from 'moment';
import "vue-time-date-range-picker/dist/vdprDatePicker.min.css"

//Live
axios.defaults.baseURL = 'https://api.facedet.com'
//Dev 
//axios.defaults.baseURL = 'http://127.0.0.1:2403'
/*
createApp({
  extends: App,
  mixins: [gMixin],
  components: {
  }
}).use(store).use(router, axios).mount('#app')*/

const app = createApp(App).use(i18n)
app.mixin({
  methods: {
	formatDate: function (text,input_format,output_format) {
		
		return moment(text,input_format).format(output_format)
	},
	changeLanguage: function (key) {
        var vm = this;
        this.$confirm('Do you really want to change language?', 'Change language', {
        }).then(() => {
          vm.$route.params['lang'] = key
          vm.$i18n.locale = key
          localStorage.setItem('LOCALE', key)
          vm.activeLang = key
          vm.$notify({ title: 'Success', message: vm.$options.filters.capitalize(vm.$t('change-language-successfully')), type: 'success' })
        }).catch(() => {});
      },
    truncate: function (text, lstring, suffix) {
	  	if (undefined !== text && text.length > lstring) {
	    	return text.substring(0, 5) + suffix +text.substring(text.length-5);
	    	//return text;
	   	} else {
	    	return text;
	  	}
  	},
	convertCrypto: function (value, inCurrency, toCurrency) {
	  	if (undefined !== value && value > 0) {
			let inCurrencyRate = this.$store.state.rate.rates[inCurrency];
			let toCurrencyRate = this.$store.state.rate.rates[toCurrency]
			return (value*toCurrencyRate/inCurrencyRate).toFixed(14);
	   	} else {
	    	return value;
	  	}
  	},
	verifyToken: function() {
			console.log("calling verifyToken...");
            let aToken = JSON.parse(localStorage.getItem("accessToken"));
    		let reToken = JSON.parse(localStorage.getItem("refreshToken"));
			console.log(aToken);
			console.log(reToken);
    		const verifyData = {
            	token: aToken,
            }
            axios
                .post("/api/metamask/token/verify/", verifyData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                })
                .catch(error => {
					const refreshData = {
            	refresh: reToken,
            }
           axios
                .post("/api/metamask/token/refresh/", refreshData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                    this.$store.commit('setToken', response.data.access);
                    this.$store.commit('setAccessToken',response.data.access)
					axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.access
                })
                .catch(error => {
					
                })
                })
    	},
	verifyToken: function(acToken,reToken) {
			console.log("calling verifyToken...");
            console.log(acToken);
			console.log(reToken);
    		const vData = {
            	token: acToken,
            }
            axios
                .post("/api/metamask/token/verify/", vData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                })
                .catch(error => {
					const refreshData = {
	            	refresh: reToken,
	            }
	           axios
	                .post("/api/metamask/token/refresh/", refreshData)
	                .then(response => {
	                	console.log(response)
	                    console.log(response.data)
	                    this.$store.commit('setToken', response.data.access);
	                    this.$store.commit('setAccessToken',response.data.access)
						axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.access
	                })
	                .catch(error => {
						
	                })
                })
    	},
	/*
	  refreshToken: function(reToken) {
			console.log("calling acToken...");
            
			const refreshData = {
            	refresh: reToken,
            }
           axios
                .post("/api/metamask/token/refresh/", refreshData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                    this.$store.commit('setToken', response.data.access);
                    this.$store.commit('setAccessToken',response.data.access)
					axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.access
                })
                .catch(error => {
					
                })
    	},*/
  }
})
app.use(VueLoading)
app.use(store).use(router, axios).mount('#app')


<template>
	<div class="breadcrumb-area pt-20 pb-20">
		<div class="container">
			<div class="row">
				<div class="col-lg-12" v-if="this.item">
					<ul class="breadcrumb-list">
						<li class="breadcrumb-list__item"><a href="/">{{$t('Home')}}</a></li>
						<li class="breadcrumb-list__item"><a href="/items/">{{$t('Shop')}}</a></li>
						<li class="breadcrumb-list__item breadcrumb-list__item--active" v-if="'vi' == $i18n.locale">{{item.title_vi}}</li>
						<li class="breadcrumb-list__item breadcrumb-list__item--active" v-else-if="'en' == $i18n.locale">{{item.title_en}}</li>
						<li class="breadcrumb-list__item breadcrumb-list__item--active" v-else>{{item.title}}</li>
					</ul>
					<!--=======  End of breadcrumb list  =======-->
				</div>
			</div>
		</div>
	</div>
	<div class="shop-page-wrapper mt-30 mb-100">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<!--=======  shop product content  =======-->

					<div class="shop-product" v-if="this.item">
						<div class="row pb-100">
							<div class="col-lg-8 mb-md-70 mb-sm-70">
								<!--=======  shop product big image gallery  =======-->
								<!--=======  End of shop product big image gallery  =======-->

								<!--=======  shop product small image gallery  =======-->

								<div class="shop-product__small-image-gallery-wrapper" v-if="this.item.item_images">
									<Carousel :items-to-scroll="1" :items-to-show="1">
								    <template #slides>
								      <Slide v-for="item_image in item.item_images" :key="item_image.id">
								        <img :src="item_image.image_url" />
								      </Slide>
								    </template>
								    <template #addons>
								      <Navigation />
								      <Pagination />
								    </template>
								  </Carousel>
								</div>

								<!--=======  End of shop product small image gallery  =======-->
							</div>

							<div class="col-lg-4">
								<!--=======  shop product description  =======-->

								<div class="shop-product__description">
									<!--=======  shop product navigation  =======-->

									<!--=======  End of shop product navigation  =======-->

									<!--=======  shop product rating  =======-->

									<div class="shop-product__rating mb-15">
										<span class="product-rating">
											<i class="active ion-android-star"></i>
											<i class="active ion-android-star"></i>
											<i class="active ion-android-star"></i>
											<i class="active ion-android-star"></i>
											<i class="ion-android-star-outline"></i>
										</span>
									</div>
									<!--=======  End of shop product rating  =======-->
									<!--=======  shop product title  =======-->
									<div class="shop-product__title mb-15">
										<h2 v-if="'vi' == $i18n.locale">{{item.title_vi}}</h2>
										<h2 v-else-if="'en' == $i18n.locale">{{item.title_en}}</h2>
										<h2 v-else>{{item.title}}</h2>
									</div>

									<!--=======  End of shop product title  =======-->

									<!--=======  shop product price  =======-->

									<select class="shop-product__price mb-20 form-group" v-if="item.metadata">
											<option v-for="metad in item.metadata">
											<span class="discounted-price" v-if="metad.price > 0">{{metad.currency.symbol}} {{metad.price}} {{metad.unit.name}}</span>
											</option>
									</select>
									<!--=======  End of shop product price  =======-->
									<!--=======  shop product short description  =======-->
									<div class="shop-product__short-desc mb-50">
										<div id="desc" v-if="'vi' == $i18n.locale">{{item.description_vi}}</div>
										<div id="desc" v-else-if="'en' == $i18n.locale">{{item.description_en}}</div>
										<div id="desc" v-else>{{item.description}}</div>
										<hr/>
										<div v-if="'vi' == $i18n.locale" v-html="item.content_vi"></div>
										<div v-else-if="'en' == $i18n.locale" v-html="item.content_en"></div>
										<div v-else v-html="item.content"></div>
									</div>
									<!--=======  End of shop product short description  =======-->
									<div class="order">
										<div class="form-group">
											<input type="text" name="fullname" id="fullname" class="form-control" ref="fullname" placeholder="Fullname">
										</div>
										<div class="form-group">
											<input type="text" name="email" id="email" class="form-control" ref="email" placeholder="Email">
										</div>
										<div class="form-group">
											<input type="text" name="phone" id="phone" class="form-control" ref="phone" placeholder="Phone">
										</div>
										<div class="form-group">
											<input type="text" name="address" id="address" class="form-control" ref="address" placeholder="Address">
										</div>
										<div class="form-group">
											<textarea name="note" class="form-control" id="note" ref="note" cols="30" rows="5" placeholder="Note"></textarea>
										</div>
										<input type="hidden" name="content_type" id="content_type" class="form-control" ref="content_type" :value="item.content_type_id">
										<input type="hidden" name="object_id" id="object_id" class="form-control" ref="object_id" :value="item.id">
										<div class="form-group">
											<button type="submit" title="Đặt ngay" class="lezada-button lezada-button--medium mt-10 mb-10 primary" id="product-addtocart-button" v-on:click="objectRegistration">
								                Place Order
								            </button>
								            <p>Pick up at Store or in your door</p>
										</div>
									</div>									
								</div>
							</div>
						</div>
					</div>
					<!--=======  End of shop product content  =======-->
				</div>
			</div>
		</div>
	</div>
</template>
<script>
//import PayPal from 'vue-paypal-checkout'
import LayoutItem from '../layouts/LayoutItem.vue';
import axios from 'axios'
import config from '../config.js';
import { defineComponent, registerRuntimeCompiler, toRefs } from "vue";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import StarRating from 'vue-star-rating';

export default {
    name: 'Item',
    components: {
    	Carousel,
	    Slide,
	    Pagination,
	    Navigation,
	    StarRating,
	    //PayPal
	},
    data() {
        return {
        	loaded: false,
            paidFor: false,
        	credentials: {
        	      sandbox: 'AQW2oFIEk1KY6UUDnKklvsTSvRkbkVJYeQ7YPqNWGuY-5ZIxePLFJOHoXRFg-Ofv161CclrClFaMZJDb',
        	      production: 'AQpwuTEs74jVdwGYks5vAd9Rr-IEw17xyzdbB02zzdb_7EIE12I-UYzh7fpOa8I9HtBPo63GCGKVbOa2'
         	},
            item: null,
            quantity: 1,
            user:{},
            rate:1,
            showImagePreview:true,
            showVideoPreview:false,
            api_baseurl:"",
            file_type:"image",
            object_registration_id:0,
        }
    },
    
    mounted() {
    	const plugin = document.createElement("script");
    	plugin.setAttribute("src","https://cdnjs.cloudflare.com/ajax/libs/fotorama/4.6.4/fotorama.min.js");
    	plugin.setAttribute("integrity","sha512-cWEytOR8S4v/Sd3G5P1Yb7NbYgF1YAUzlg1/XpDuouZVo3FEiMXbeWh4zewcYu/sXYQR5PgYLRbhf18X/0vpRg==");
    	plugin.setAttribute("crossorigin","anonymous");
    	plugin.setAttribute("referrerpolicy","no-referrer");
    	plugin.async = true;
    	document.head.appendChild(plugin);
    	
    	const linkTag = document.createElement("link");
    	linkTag.setAttribute("rel","stylesheet");
    	linkTag.setAttribute("href","https://cdnjs.cloudflare.com/ajax/libs/fotorama/4.6.4/fotorama.min.css");
    	linkTag.setAttribute("integrity","sha512-bjwk1c6AQQOi6kaFhKNrqoCNLHpq8PT+I42jY/il3r5Ho/Wd+QUT6Pf3WGZa/BwSdRSIjVGBsPtPPo95gt/SLg==");
    	linkTag.setAttribute("crossorigin","anonymous");
    	linkTag.setAttribute("referrerpolicy","no-referrer");
    	linkTag.async = true;
    	document.head.appendChild(linkTag);
    	
    	this.api_baseurl= axios.defaults.baseURL
        this.getItem() 
        this.rate = this.$store.state.rate
    },
    methods: {
    	async objectRegistration() {
    		const user = JSON.parse(localStorage.getItem("user"));
    		const formData = {
	 			fullname: this.$refs.fullname.value||"undefined",
				email: this.$refs.email.value||"undefined",
				phone: this.$refs.phone.value||"undefined",
				address: this.$refs.address.value||"undefined",
				note: this.$refs.note.value||"undefined",
				content_type: this.$refs.content_type.value||29,
				object_id: this.$refs.object_id.value||0,
				status:'created',
				user:user.id,
	    	}
    		
	    await axios
                .post("/api/objectregistrations/", formData)
                .then(response => {
                	console.log(response.data);
                	this.object_registration_id = response.data.id
                	const toPath = this.$route.query.to || '/payment/?object_registration_id='+this.object_registration_id;
                    this.$router.push(toPath);
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    	},
    	updated() {
    		$(function () {
			  $('.fotorama').fotorama();
			});
    	},
    	created() {
	    	this.$emit('update:layout', LayoutItem);
	    },
    	showVideo(event) {
    		let video = document.getElementById('video-preview');
			let reader = new FileReader();
			console.log( this.item.asset_file_url )
			video.src=axios.defaults.baseURL+this.item.asset_file_url
			this.showImagePreview = false;
			this.showVideoPreview = true;
			/*reader.readAsDataURL( new File([await (await fetch(event.target.dataset.fileurl)).blob()], event.target.dataset.fileurl) );
			reader.addEventListener('load', function(){
				this.showImagePreview = false;
				this.showVideoPreview = true;
				video.src = reader.result;
			}.bind(this), false);*/
    	},
        async getItem() {
            const category_slug = this.$route.params.category_slug
            const slug = this.$route.params.slug
            await axios
                .get(`/api/items/${slug}/?image_sizes=600x600&user_image_sizes=65x65`)
                .then(response => {
                	console.log(response.data)
                    this.item = response.data
                    document.title = this.item.title
                })
                .catch(error => {
                    console.log(error)
                })
        },
        addToCart() {
        	console.log("Add to cart");
        	
            if (isNaN(this.quantity) || this.quantity < 1) {
                this.quantity = 1
            }

            const itemData = {
                product: this.item,
                quantity: this.quantity
            }

            this.$store.commit('addToCart', itemData)
        }
    }
}
</script>
<template>
	<div class="breadcrumb-area breadcrumb-bg-1 pt-50 pb-70 mb-130">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<h1 class="breadcrumb-title">Shopping Cart</h1>
					<!--=======  breadcrumb list  =======-->
					<ul class="breadcrumb-list">
						<li class="breadcrumb-list__item"><a href="/">HOME</a></li>
						<li class="breadcrumb-list__item breadcrumb-list__item--active">Shopping Cart</li>
					</ul>
					<!--=======  End of breadcrumb list  =======-->
				</div>
			</div>
		</div>
	</div>
	<div class="shopping-cart-area mb-130">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 mb-30">
					<!--=======  cart table  =======-->

					<div class="cart-table-container">
						<table class="cart-table">
							<thead>
								<tr>
									<th class="product-name" colspan="2">Product</th>
									<th class="product-price">Price</th>
									<th class="product-quantity">Quantity</th>
									<th class="product-subtotal">Total</th>
									<th class="product-remove">&nbsp;</th>
								</tr>
							</thead>

							<tbody>
								<tr>
									<td class="product-thumbnail">
										<a href="shop-product-basic.html">
											<img src="assets/images/products/bag-1-1-600x800.jpg" class="img-fluid" alt="">
										</a>
									</td>
									<td class="product-name">
										<a href="shop-product-basic.html">Black Fabric Watch</a>
										<span class="product-variation">Color: Black</span>
									</td>

									<td class="product-price"><span class="price">$100.00</span></td>

									<td class="product-quantity">
										<div class="pro-qty d-inline-block mx-0"><a href="#" class="dec qty-btn">-</a>
											<input type="text" value="1">
										<a href="#" class="inc qty-btn">+</a></div>
									</td>

									<td class="total-price"><span class="price">$100.00</span></td>

									<td class="product-remove">
										<a href="#">
											<i class="ion-android-close"></i>
										</a>
									</td>
								</tr>
								<tr>
									<td class="product-thumbnail">
										<a href="shop-product-basic.html">
											<img src="assets/images/products/watch-1-1-600x800.jpg" class="img-fluid" alt="">
										</a>
									</td>
									<td class="product-name">
										<a href="shop-product-basic.html">Brown watch</a>
										<span class="product-variation">Color: Brown</span>
									</td>

									<td class="product-price"><span class="price">$150.00</span></td>

									<td class="product-quantity">
										<div class="pro-qty d-inline-block mx-0"><a href="#" class="dec qty-btn">-</a>
											<input type="text" value="1">
										<a href="#" class="inc qty-btn">+</a></div>
									</td>

									<td class="total-price"><span class="price">$250.00</span></td>

									<td class="product-remove">
										<a href="#">
											<i class="ion-android-close"></i>
										</a>
									</td>
								</tr>
								<tr>
									<td class="product-thumbnail">
										<a href="shop-product-basic.html">
											<img src="assets/images/products/cloth-1-1-600x800.jpg" class="img-fluid" alt="">
										</a>
									</td>
									<td class="product-name">
										<a href="shop-product-basic.html">High weist pant</a>
										<span class="product-variation">Color: Blue</span>
									</td>

									<td class="product-price"><span class="price">$10.00</span></td>

									<td class="product-quantity">
										<div class="pro-qty d-inline-block mx-0"><a href="#" class="dec qty-btn">-</a>
											<input type="text" value="1">
										<a href="#" class="inc qty-btn">+</a></div>
									</td>

									<td class="total-price"><span class="price">$260.00</span></td>

									<td class="product-remove">
										<a href="#">
											<i class="ion-android-close"></i>
										</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<!--=======  End of cart table  =======-->
				</div>
				<div class="col-lg-12 mb-80">
					<!--=======  coupon area  =======-->

					<div class="cart-coupon-area pb-30">
						<div class="row align-items-center">
							<div class="col-lg-6 mb-md-30 mb-sm-30">
								<!--=======  coupon form  =======-->

								<div class="lezada-form coupon-form">
									<form action="#">
										<div class="row">
											<div class="col-md-7 mb-sm-10">
												<input type="text" placeholder="Enter your coupon code">
											</div>
											<div class="col-md-5">
												<button class="lezada-button lezada-button--medium">apply coupon</button>
											</div>
										</div>
									</form>
								</div>

								<!--=======  End of coupon form  =======-->
							</div>

							<div class="col-lg-6 text-left text-lg-right">
								<!--=======  update cart button  =======-->

								<button class="lezada-button lezada-button--medium">update cart</button>

								<!--=======  End of update cart button  =======-->
							</div>
						</div>
					</div>

					<!--=======  End of coupon area  =======-->
				</div>

				<div class="col-xl-4 offset-xl-8 col-lg-5 offset-lg-7">
					<div class="cart-calculation-area">
						<h2 class="mb-40">Cart totals</h2>

						<table class="cart-calculation-table mb-30">
							<tbody><tr>
								<th>SUBTOTAL</th>
								<td class="subtotal">$100.00</td>
							</tr>
							<tr>
								<th>TOTAL</th>
								<td class="total">$100.00</td>
							</tr>
						</tbody></table>

						<div class="cart-calculation-button text-center">
							<button class="lezada-button lezada-button--medium">proceed to checkout</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import CartItem from '@/components/CartItem.vue'

export default {
    name: 'Cart',
    components: {
        CartItem
    },
    data() {
        return {
            cart: {
                items: []
            }
        }
    },
    mounted() {
    	document.title = "Shopping Cart";
        this.cart = this.$store.state.cart
    },
    methods: {
        removeFromCart(item) {
            this.cart.items = this.cart.items.filter(i => i.product.id !== item.product.id)
        }
    },
    computed: {
        cartTotalLength() {
            return this.cart.items.reduce((acc, curVal) => {
                return acc += curVal.quantity
            }, 0)
        },
        cartTotalPrice() {
            return this.cart.items.reduce((acc, curVal) => {
                return acc += curVal.product.price * curVal.quantity
            }, 0)
        },
    }
}
</script>
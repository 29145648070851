<template>
	<section class="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65" v-if="this.post_type">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<h1>{{post_type.title}}</h1>
					<ol class="breadcrumb">
						<li class="breadcrumb-item">
							<a href="/">Trang Chủ</a>
						</li>
						<li class="breadcrumb-item active">
							{{post_type.title}}
						</li>
					</ol>
				</div>
			</div>
		</div>
	</section>
	<section class="ds s-pt-90 s-pb-60 s-pt-xl-150 s-pb-xl-120 c-gutter-60" v-if="this.posts">
				<div class="container">

					<div class="row c-mb-60">

						<main class="col-12">
							<div class="row isotope-wrapper masonry-layout">
								<div class="col-xl-4 col-md-6" v-for="object in this.posts">
									<article class="vertical-item bg-darkblue text-center text-lg-left box-shadow content-padding padding-big post type-post status-publish format-standard has-post-thumbnail">
										<div style="display:none;" class="item-media post-thumbnail">
											<a :href="'/posts/'+object.slug+'/'">
												<img :src="object.featured_thumbnail_url" :alt="object.title">
											</a>
										</div><!-- .post-thumbnail -->
										<div class="item-content">
											<header class="entry-header">
												<h4 class="entry-title mb-3 links-maincolor2">
													<a class="fs-30" :href="'/posts/'+object.slug+'/'" rel="bookmark">
														{{object.title}}
													</a>
												</h4>
											</header>
											<!-- .entry-header -->

											<div class="entry-content mt-20">
												<p>
													{{object.description}}
												</p>
											</div><!-- .entry-content -->
											<div class="entry-footer flex-row mt-4">
												<div class="entry-meta">
													<span>
														<a :href="'/posts/'+object.slug+'/'" rel="bookmark">
															<time class="entry-date published updated" :datetime="object.created_at"> {{ formatDate(object.created_at,'YYYY-MM-DD',"DD.MM.YYYY") }}</time>
														</a>
													</span>
												</div>
												<!-- .entry-meta -->
												<div class="mt-0 ml-auto">
													<a :href="'/posts/'+object.slug+'/'" class="mt-30 font-2">Chi tiết <i class="fa fa-caret-right color-main ml-2"></i></a>
												</div>
											</div>
										</div><!-- .item-content -->
									</article><!-- #post-## -->
								</div>
							</div>

							<div class="mt--60"></div>

							<nav class="navigation pagination justify-content-center" role="navigation" v-if="this.showPagination">
								<h2 class="screen-reader-text">Posts navigation</h2>
								<div class="nav-links">
									<a v-if="this.showPrev" class="prev page-numbers" v-on:click="loadPrev()">
										<i class="fa fa-chevron-left"></i>
										<span class="screen-reader-text">Previous page</span>
									</a>
									<span class="page-numbers current">
										<span class="meta-nav screen-reader-text">Page </span>
										{{this.currentPage}}
									</span>
									
									<a class="next page-numbers" v-if="this.showNext" v-on:click="loadNext()">
										<span class="screen-reader-text">Next page</span>
										<i class="fa fa-chevron-right"></i>
									</a>
								</div>
							</nav>
						</main>
					</div>
				</div>
	</section>
</template>
<script>
import axios from 'axios'
export default {
    name: 'PostType',
  	data() {
	    return {
	    	posts:[],
	    	api_baseurl:"",
	    	count:0,
	      	currentPage:1,
	     	page_size:6,
	      	showNext:false,
	      	showPrev:false,
	      	showPagination:false,
	      	num_pages:0,
	      	fullPage:false,
	      	post_type:null,
	    }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        document.title = this.$t('Blog')
        this.getPosts()
    },
    methods: {
    	loadPrev: function () {
    		this.currentPage -=1
    		this.getPosts()
    	},
    	loadNext: function () {
    		this.currentPage +=1
    		this.getPosts()
    	},
    	async getPosts() {
    		if (!this.currentPage) {
				this.currentPage = 1
			} 
			
			let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    onCancel: this.onCancel,
                });
            
            const typeSlug = this.$route.params.type_slug
            console.log(typeSlug)
                
	  		await axios
	                .get("/api/posts/?type_slug="+typeSlug+"&page="+this.currentPage+"&page_size=6")
	                .then(response => {
						console.log(response.data.results)
						this.posts = response.data.results
						if(response.data.count>0) {
							this.post_type = this.posts[0].type
						}
						if (response.data.count == 0) {
	                		this.showPagination = false 
	                	} else {
	                		this.showPagination = true
	                	}
	                	this.count = response.data.count;
	                	this.num_pages = this.count/this.page_size;
	                	if(response.data.next) {
	                    	this.showNext=true
	                    } else {
	                    	this.showNext=false
	                    }
	                    if(response.data.previous) {
	                    	this.showPrev=true
	                    } else {
	                    	this.showPrev=false
	                    }
	                	 setTimeout(() => {
		                    loader.hide()
		                }, 1000)
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
    },
    updated() {
	  	$(document).ready(function(){
	  		//$('.blog-masonry').masonry({percentPosition:true,itemSelector:'.column',columnWidth:'.grid-sizer',})	
	  	})
  	}
}
</script>
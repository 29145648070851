<template>
	<div class="breadcrumb-area breadcrumb-bg-1 pt-50 pb-70 mb-100">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<h1 class="breadcrumb-title">Retreat</h1>
					<!--=======  breadcrumb list  =======-->
					<ul class="breadcrumb-list">
						<li class="breadcrumb-list__item"><a href="/">HOME</a></li>
						<li class="breadcrumb-list__item breadcrumb-list__item--active">Retreat</li>
					</ul>
					<!--=======  End of breadcrumb list  =======-->

				</div>
			</div>
		</div>
	</div>
	<div class="blog-page-wrapper mb-100">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 order-1">
					<div class="row">
						<div class="col-md-4 mb-60" v-for="object in this.posts"> 
							<div class="single-slider-post">
								<!--=======  image  =======-->
								<div class="single-slider-post__image mb-30">
									<a :href="'/posts/'+object.slug+'/'">
										<img :src="object.featured_thumbnail_url" class="img-fluid" :alt="object.title">
									</a>
								</div>
								<!--=======  End of image  =======-->
								<!--=======  content  =======-->

								<div class="single-slider-post__content">
									<div class="post-date">
										<i class="ion-android-calendar"></i>
										<a :href="'/posts/'+object.slug+'/'">{{ formatDate(object.created_at,'YYYY-MM-DD',"DD.MM.YYYY") }}</a>
									</div>
									<h2 class="post-title">
										<a :href="'/posts/'+object.slug+'/'" v-if="'vi' == $i18n.locale">{{object.title_vi}}</a>
										<a :href="'/posts/'+object.slug+'/'" v-else-if="'en' == $i18n.locale">{{object.title_en}}</a>
										<a :href="'/posts/'+object.slug+'/'" v-else>{{object.title}}</a>
									</h2>
									
									<div class="shop-product__price mb-20 form-group" v-if="object.metadata">
										<ul v-for="metad in object.metadata">
											<li class="discounted-price">{{metad.currency.symbol}} {{metad.price}} {{metad.unit.name}}</li>
										</ul>
									</div>
									
									<p class="post-excerpt" v-if="'vi' == $i18n.locale">{{object.description_vi}}</p>
									<p class="post-excerpt" v-else-if="'en' == $i18n.locale">{{object.description_en}}</p>
									<p class="post-excerpt" v-else>{{object.description}}</p>
									
									<a :href="'/posts/'+object.slug+'/'" class="blog-readmore-btn">{{$t('read more')}}</a>
								</div>

								<!--=======  End of content  =======-->
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-lg-12">
							<!--=======  pagination  =======-->

							<div class="pagination text-center">
								<ul>
									<li v-if="this.showPrev" v-on:click="loadPrev()"><a href="">PREV</a></li>
									<li class="active">{{this.currentPage}}</li>
									<li v-if="this.showNext" v-on:click="loadNext()"><a href="">NEXT</a></li>
								</ul>
							</div>

							<!--=======  End of pagination  =======-->
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'RetreatPage',
  	data() {
	    return {
	    	posts:[],
	    	api_baseurl:"",
	    	count:0,
	      	currentPage:1,
	     	page_size:6,
	      	showNext:false,
	      	showPrev:false,
	      	showPagination:false,
	      	num_pages:0,
	      	fullPage:false,
	    }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        document.title = 'Retreat'
        this.getPosts()
    },
    methods: {
    	loadPrev: function () {
    		this.currentPage -=1
    		this.getPosts()
    	},
    	loadNext: function () {
    		this.currentPage +=1
    		this.getPosts()
    	},
    	async getPosts() {
    		if (!this.currentPage) {
				this.currentPage = 1
			} 
			
			let loader = this.$loading.show({
                    // Optional parameters
            	container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: true,
                onCancel: this.onCancel,
            });
            
            //const typeSlug = this.$route.query.type
            //console.log(typeSlug)
                
	  		await axios
	                .get("/api/posts/?type=3&page="+this.currentPage+"&page_size=6")
	                .then(response => {
						console.log(response.data.results)
						this.posts = response.data.results
						
						if (response.data.count == 0) {
	                		this.showPagination = false 
	                	} else {
	                		this.showPagination = true
	                	}
	                	this.count = response.data.count;
	                	this.num_pages = this.count/this.page_size;
	                	if(response.data.next) {
	                    	this.showNext=true
	                    } else {
	                    	this.showNext=false
	                    }
	                    if(response.data.previous) {
	                    	this.showPrev=true
	                    } else {
	                    	this.showPrev=false
	                    }
	                	setTimeout(() => {
		                    loader.hide()
		                }, 1000)
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
    },
    updated() {
	  	$(document).ready(function(){
	  		//$('.blog-masonry').masonry({percentPosition:true,itemSelector:'.column',columnWidth:'.grid-sizer',})	
	  	})
  	}
}
</script>
<template>
	<section class="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<h1>Xem Thần Số Học</h1>
					<ol class="breadcrumb">
						<li class="breadcrumb-item">
							<a href="/">Trang Chủ</a>
						</li>
						<li class="breadcrumb-item">
							Thần Số Học	
						</li>
						<li class="breadcrumb-item active">
							{{fullname}} - {{birthday}}		
						</li>
					</ol>
				</div>
			</div>
		</div>
	</section>
	<section class="ds s-pt-60 c-gutter-60">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="text-center">
								<h2>Số Chủ Đạo (Số Đường Đời)</h2>
								<h1><span style="padding:20px;">{{this.lifePathNumber}}</span></h1>
							</div>	
						</div>
					</div>
				</div>
	</section>
	<section class="ds s-pt-60 c-gutter-60">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="text-center">
								<div id="mandala" style="height:400px">
									<span class="large-circles">
									  <div class="large circle one"></div>
									  <div class="large circle two"></div>
									  <div class="large circle three"></div>
									  <div class="large circle four"></div>
									  <div class="large circle five"></div>
									  <div class="large circle six"></div>
									  <div class="large circle seven"></div>
									  <div class="large circle eight"></div>
									</span>
									
									<span class="small-shapes">
									  <div class="small circle one"></div>
									  <div class="small squircle two"></div>
									  <div class="small circle three"></div>
									  <div class="small squircle four"></div>
									  <div class="small circle five"></div>
									  <div class="small squircle six"></div>
									  <div class="small circle seven"></div>
									  <div class="small squircle eight"></div>
									</span>
									
									<span class="content-squircle">
									  <div class="large squircle one"></div>
									</span>
								</div>
							</div>	
						</div>
					</div>
				</div>
	</section>
	<section class="ds s-pt-60 c-gutter-60">
				<div class="container">
					<div class="row" v-if="this.lifePathNumber">
						<div class="col-lg-12">
							<div class="divider-60 divider-lg-0"></div>
							<div class="vertical-item content-padding padding-big corner-box text-md-left text-center">
								<div class="item-content">
									<div class="media vertical_box big-media">
										<div class="icon-styled color-darkgrey fs-200">
											1.
										</div>
										<div class="media-body">
											<h6 class="mb-0 fs-40 color-main2">
												CHỈ SỐ ĐƯỜNG ĐỜI CỦA BẠN LÀ: {{this.lifePathNumber}}
											</h6>
											<div v-for="object in this.lifePathNumber_posts" class="fs-20">
												<div v-if="'so-duong-doi-'+this.lifePathNumber == object.slug" v-html="object.content"></div>
											</div>
										</div>
									</div>
									<div class="divider-30 divider-xl-50"></div>
									<div class="number-content"></div>
								</div>
							</div>

						</div><!-- .col-* -->
					</div>
					<div class="row" v-if="this.missionNumber">
						<div class="col-lg-12">
							<div class="divider-60 divider-lg-0"></div>
							<div class="vertical-item content-padding padding-big corner-box text-md-left text-center">
								<div class="item-content">
									<div class="media vertical_box big-media">
										<div class="icon-styled color-darkgrey fs-200">
											2.
										</div>
										<div class="media-body">
											<h6 class="mb-0 fs-40 color-main2">
												CHỈ SỐ SỨ MỆNH CỦA BẠN LÀ: {{this.missionNumber}}
											</h6>
											<div v-for="object in this.missionNumber_posts" class="fs-20">
												<div v-if="'chi-so-su-menh-'+this.missionNumber == object.slug" v-html="object.content"></div>
											</div>
										</div>
									</div>
									<div class="divider-30 divider-xl-50"></div>
									<div class="number-content"></div>
								</div>
							</div>

						</div><!-- .col-* -->
					</div>
					<div class="row" v-if="this.soulNumber">
						<div class="col-lg-12">
							<div class="divider-60 divider-lg-0"></div>
							<div class="vertical-item content-padding padding-big corner-box text-md-left text-center">
								<div class="item-content">
									<div class="media vertical_box big-media">
										<div class="icon-styled color-darkgrey fs-200">
											3.
										</div>
										<div class="media-body">
											<h6 class="mb-0 fs-40 color-main2">
												CHỈ SỐ LINH HỒN CỦA BẠN LÀ: {{this.soulNumber}}
											</h6>
											<div v-for="object in this.soulNumber_posts" class="fs-20">
												<div v-if="'chi-so-linh-hon-'+this.soulNumber == object.slug" v-html="object.content"></div>
											</div>
										</div>
									</div>
									<div class="divider-30 divider-xl-50"></div>
									<div class="number-content"></div>
								</div>
							</div>

						</div><!-- .col-* -->
					</div>
					
					<div class="row">
						<div class="col-lg-12">
							<div id="message" style="color: green; font-size:20px;text-align:center;display:none;">cám ơn bạn đã đăng ký, chúng tôi sẽ liên hệ lại bạn ngay !</div>
							<div class="divider-60 divider-lg-0"></div>
							<div class="vertical-item content-padding padding-big corner-box text-md-left text-center">
								<div class="item-content" style="text-align:center">
									<button type="button" data-toggle="modal" data-target="#popupConsultant">Nhận Kết Quả Chuyên Sâu Miễn Phí</button>
									<button type="button" data-toggle="modal" data-target="#popupBusiness" style="margin-left:20px;">Hợp Tác Kinh Doanh</button>
								</div>
							</div>
						</div>
					</div>
		</div>
	</section>
	
	<div class="modal fade popupRegistr" id="popupConsultant" ref="popupConsultant" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content ds bs box-shadow bordered overflow-visible s-overlay s-mobile-overlay">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<div class="modal-body">
					<div class="container">
						<div class="row">
							<div class="col-12">
								<h4 class="color-main2 mb-4">Nhận Kết Quả Chuyên Sâu Miễn Phí</h4>
								<div class="form-registration c-mb-20 c-gutter-20">
									<div class="row">
										<div class="col-12">
											<div class="form-group">
												<input type="text" ref="fullname" name="fullname" :value="fullname" class="form-control" required placeholder="fullname" aria-required="true">
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<input type="email" ref="email" name="email" :value="email" class="form-control" placeholder="email" required aria-required="true">
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<input type="text" ref="phone" name="phone" :value="phone" class="form-control" placeholder="phone" required aria-required="true">
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<input type="text" ref="recommend_phone" name="recommend_phone" class="form-control" placeholder="SĐT người giới thiệu">
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<textarea name="note" ref="note" class="form-control" placeholder="note" required aria-required="true">{{note}}</textarea>
											</div>
										</div>
										<input type="hidden" name="gender" :value="gender" ref="gender"/>
										<input type="hidden" name="content_type" id="content_type" class="form-control" ref="content_type" value="44">
										<input type="hidden" name="object_id" id="object_id" class="form-control" ref="object_id" value="3">
									</div>
									<button type="submit" class="btn btn-maincolor mt-30 d-block" v-on:click="registerObject()">Gửi</button>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal fade popupRegistr" id="popupBusiness" ref="popupBusiness" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content ds bs box-shadow bordered overflow-visible s-overlay s-mobile-overlay">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<div class="modal-body">
					<div class="container">
						<div class="row">
							<div class="col-12">
								<h4 class="color-main2 mb-4">Hợp Tác kinh Doanh</h4>
								<div class="form-registration c-mb-20 c-gutter-20">
									<div class="row">
										<div class="col-12">
											<div class="form-group">
												<input type="text" ref="biz_fullname" name="fullname" :value="fullname" class="form-control" required placeholder="fullname" aria-required="true">
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<input type="email" ref="biz_email" name="email" :value="email" class="form-control" placeholder="email" required aria-required="true">
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<input type="text" ref="biz_phone" name="phone" :value="phone" class="form-control" placeholder="phone" required aria-required="true">
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<input type="text" ref="biz_recommend_phone" name="recommend_phone" class="form-control" placeholder="SĐT người giới thiệu">
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<textarea name="note" ref="biz_note" class="form-control" placeholder="note" required aria-required="true">{{note}}</textarea>
											</div>
										</div>
										<input type="hidden" name="gender" :value="gender" ref="biz_gender"/>
										<input type="hidden" name="content_type" id="content_type" class="form-control" ref="biz_content_type" value="44">
										<input type="hidden" name="object_id" id="object_id" class="form-control" ref="biz_object_id" value="2">
									</div>
									<button type="submit" class="btn btn-maincolor mt-30 d-block" v-on:click="registerBusiness()">Gửi</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DataAPI from '@/apis/data'
export default {
    name: 'Numerology',
    props: {
    },
    components: {
    },
    data() {
        return {
        	fullname:"",
        	birthday:"",
        	phone:"",
        	email:"",
        	gender:"",
        	note:"",
        	object_registration_id:0,
        	content_type:null,
        	object_id:null,
        	fullPage: false,
            page: {},
            miscData:{},
            lifePathNumber:0,
            missionNumber:0,
            soulNumber:0,
            maturityNumber:0,
            balanceNumber:0,
            personalityNumber:0,
            birthdayNumber:0,
            yearNumber:0,
            monthNumber:0,
            dayNumber:0,
            lifePathMissionLinkNumber:0,
            soulPersonalityLinkNumber:0,
            missingNumbers:0,
            currentYear:new Date().getFullYear(),
            currentMonth:new Date().getMonth()+1,
            currentDay:new Date().getDay(),
            lifePathNumber_posts:null,
            missionNumber_posts:null,
            soulNumber_posts:null,
            
        }
    },
    mounted() {
    	const linkTag = document.createElement("link");
    	linkTag.setAttribute("rel","stylesheet");
    	linkTag.setAttribute("href","../assets/css/custom.css");
    	linkTag.async = true;
    	document.head.appendChild(linkTag);
    	console.log(this.currentMonth);
    	
    	document.title = this.$t('Numerology');
    	this.miscData = JSON.parse(localStorage.getItem("miscData"));
    	this.fullname = this.$route.query.fullname;
    	this.birthday = this.$route.query.birthday;
    	this.phone = this.$route.query.phone;
    	this.email = this.$route.query.email;
    	this.gender = this.$route.query.gender;
    	this.object_registration_id = this.$route.query.object_registration_id;
    	this.calculateLifePathNumber();
    	this.calculateMissionNumber();
    	this.calculateMaturityNumber();
    	this.calculateSoulNumber();
    	this.calculateBalanceNumber();
    	this.calculatePersonalityNumber();
    	this.calculateBirthdayNumber();
    	this.calculateYearNumber();
    	this.calculateMonthNumber();
    	this.calculateDayNumber();
    	this.calculateLifePathMissionLinkNumber();
    	this.calculateSoulPersonalityLinkNumber();
    	this.calculateMissingNumber();
    	this.getLifePathNumberPosts();
    	this.getMissionNumberPosts();
    	this.getSoulNumberPosts();
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Numberology') {
                //this.getPage()
            }
        }
    },
    methods: {
    	getLifePathNumberPosts() {
    		DataAPI.getPosts({type:9,page_size: 20 }).then(res => {
    		     this.lifePathNumber_posts=res.data.results
    		})	
    	},
    	getMissionNumberPosts() {
    		DataAPI.getPosts({type:10,page_size: 20 }).then(res => {
    		     this.missionNumber_posts=res.data.results
    		})	
    	},
    	getSoulNumberPosts() {
    		DataAPI.getPosts({type:11,page_size: 20 }).then(res => {
    		     this.soulNumber_posts=res.data.results
    		})	
    	},
    	removeVietnameseTones(str, toUpperCase = false) {
    		    str = str.toLowerCase();
    		    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    		    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    		    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    		    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    		    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    		    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    		    str = str.replace(/đ/g, "d");
    		    // Some system encode vietnamese combining accent as individual utf-8 characters
    		    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
    		    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    		    return toUpperCase ? str.toUpperCase() : str;
    	},
    	calculateLifePathMissionLinkNumber() {
    		this.lifePathMissionLinkNumber = Math.abs(this.lifePathNumber - this.missionNumber)
    	},
    	calculateSoulPersonalityLinkNumber() {
    		this.soulPersonalityLinkNumber = Math.abs(this.soulNumber - this.personalityNumber)
    	},
    	calculateMissingNumber() {
    		let birthday = this.birthday;
    		let fullname = this.removeVietnameseTones(this.fullname);
    		let birthdayArray = birthday.split('-');
    		let year = birthdayArray[0];
    		let month = birthdayArray[1];
    		let day = birthdayArray[2];
    		const data_map = { "a": 1, "j": 1, "s": 1, "b": 2, "k": 2, "t":2, "c":3, "l":3, "u":3, "d":4, "m":4, "v":4, "e":5, "n":5, "w":5, "f":6, "o":6, "x":6, "g":7, "p":7, "y":7, "h":8, "q":8, "z":8, "i":9, "r":9}
    		const numbers = [1,2,3,4,5,6,7,8,9]
    		let fullnameArray = fullname.split(" ")
    		let arr = []
    		let missingNumbers = [1,2,3,4,5,6,7,8,9]
    		fullnameArray.forEach((value, index) => {
	    		let i 
	    		let char_total = 0;
	    		
	    		for(i=0;i<=value.length-1;i++){
	    			let j
	    			for(j=0;j<=numbers.length-1;j++) {
	    				if(numbers[j] == data_map[value[i]]) {
	    					missingNumbers.splice(j, 1);
	    				}
	    			}
	    			arr.push(data_map[value[i]]);
	    		}
			});
    		
    		
    		this.missingNumbers = missingNumbers
    		console.log(this.missingNumbers)
    	},
    	calculateDayNumber() {
    		this.dayNumber = this.monthNumber + this.currentDay
    		while(this.dayNumber>9) {
				let j;
				let l_total=0;
				for(j=0;j<=String(this.dayNumber).length-1;j++){
					l_total = l_total + Number(String(this.dayNumber)[j]);	
				}
				this.dayNumber = l_total;
			}	
    	},
    	calculateMonthNumber() {
    		this.monthNumber = this.yearNumber + this.currentMonth
    		while(this.monthNumber>9) {
				let j;
				let l_total=0;
				for(j=0;j<=String(this.monthNumber).length-1;j++){
					l_total = l_total + Number(String(this.monthNumber)[j]);	
				}
				this.monthNumber = l_total;
			}	
    	},
    	calculateMaturityNumber() {
    		let maturityNumber = this.lifePathNumber + this.missionNumber
    		while(maturityNumber>9) {
				let j;
				let l_total=0;
				for(j=0;j<=String(maturityNumber).length-1;j++){
					l_total = l_total + Number(String(maturityNumber)[j]);	
				}
				maturityNumber = l_total;
				console.log(l_total);
				if ([11,22,33].includes(maturityNumber)) {
	    			break;
	    		}
			}	
    		this.maturityNumber=maturityNumber;
    	},
    	calculateBirthdayNumber() {
    		let birthday = this.birthday;
    		let fullname = this.removeVietnameseTones(this.fullname);
    		let birthdayArray = birthday.split('-');
    		let year = birthdayArray[0];
    		let month = birthdayArray[1];
    		let day = birthdayArray[2];
    		const data_map = { "a": 1, "j": 1, "s": 1, "b": 2, "k": 2, "t":2, "c":3, "l":3, "u":3, "d":4, "m":4, "v":4, "e":5, "n":5, "w":5, "f":6, "o":6, "x":6, "g":7, "p":7, "y":7, "h":8, "q":8, "z":8, "i":9, "r":9}
    		const phuam_data_map = {"a":1,"u":3, "e":5, "o":6, "i":9}
    		let fullnameArray = fullname.split(" ")
    		
    		let loop = true
    		let char_total = 0;
    		if ([11,22,33].includes(Number(day))) {
    			loop = false
    			char_total = day
    		}
    		while(loop) {
				let j;
				let l_total=0;
				for(j=0;j<=String(day).length-1;j++){
					l_total = l_total + Number(String(day)[j]);	
				}
				char_total = l_total;
				console.log(l_total);
				if (char_total < 10) {
					loop = false
				}
				if ([11,22,33].includes(char_total)) {
					loop = false
	    		}
			}	
    		
    		this.birthdayNumber = char_total;
    	},
    	calculateYearNumber() {
    		let birthday = this.birthday;
    		let fullname = this.removeVietnameseTones(this.fullname);
    		let birthdayArray = birthday.split('-');
    		let year = birthdayArray[0];
    		let month = birthdayArray[1];
    		let day = birthdayArray[2];
    		const data_map = { "a": 1, "j": 1, "s": 1, "b": 2, "k": 2, "t":2, "c":3, "l":3, "u":3, "d":4, "m":4, "v":4, "e":5, "n":5, "w":5, "f":6, "o":6, "x":6, "g":7, "p":7, "y":7, "h":8, "q":8, "z":8, "i":9, "r":9}
    		const phuam_data_map = {"a":1,"u":3, "e":5, "o":6, "i":9}
    		let fullnameArray = fullname.split(" ")
    		let yearNumberArray = []
    		yearNumberArray.push(day)
    		yearNumberArray.push(month)
    		yearNumberArray.push(this.currentYear)
    		console.log(yearNumberArray)
    		
    		let arr = []
    		yearNumberArray.forEach((value, index) => {
	    		let i 
	    		let char_total = 0;
	    		
	    		for(i=0;i<=String(value).length-1;i++){
	    			char_total = char_total + Number(String(value)[i]);
	    			console.log(char_total);
	    		}
	    		
	    		if (true) {
	    			while(char_total>9) {
	    				let j;
	    				let l_total=0;
	    				for(j=0;j<=String(char_total).length-1;j++){
	    					l_total = l_total + Number(String(char_total)[j]);	
	    				}
	    				char_total = l_total;
	    			}	
	    			arr.push(char_total);
	    		}
	    		
	    		let k;
	    		let total = 0;
	    		for(k=0;k<=arr.length-1;k++){
	    			total = total + arr[k];
	    		}
	    		while(total>9) {
	    			let j;
    				let l_total=0;
    				for(j=0;j<=String(total).length-1;j++){
    					l_total = l_total + Number(String(total)[j]);	
    				}
    				total = l_total;	
	    		}
	    		console.log(arr);
	    		this.yearNumber = total;
			});
    		
    		
    	},
    	calculatePersonalityNumber() {
    		let birthday = this.birthday;
    		let fullname = this.removeVietnameseTones(this.fullname);
    		let birthdayArray = birthday.split('-');
    		let year = birthdayArray[0];
    		let month = birthdayArray[1];
    		let day = birthdayArray[2];
    		const data_map = { "a": 1, "j": 1, "s": 1, "b": 2, "k": 2, "t":2, "c":3, "l":3, "u":3, "d":4, "m":4, "v":4, "e":5, "n":5, "w":5, "f":6, "o":6, "x":6, "g":7, "p":7, "y":7, "h":8, "q":8, "z":8, "i":9, "r":9}
    		const phuam_data_map = {"a":1,"u":3, "e":5, "o":6, "i":9}
    		let fullnameArray = fullname.split(" ")
    		let arr = []
    		fullnameArray.forEach((value, index) => {
	    		let i 
	    		let char_total = 0;
	    		
	    		for(i=0;i<=value.length-1;i++){
	    			if ([ "b", "c", "d", "g", "h", "k", "l", "m", "n", "p", "q", "r", "s", "t", "v", "x"].includes(value[i])) {
	    				char_total = char_total + data_map[value[i]];
	    				console.log(data_map[value[i]]);
	    			}
	    		}
	    		
	    		if ([11,22,33].includes(char_total)) {
	    			arr.push(char_total);
	    		} else {
	    			console.log(char_total);
	    			while(char_total>9) {
	    				console.log(char_total);
	    				console.log(String(char_total).length);
	    				let j;
	    				let l_total=0;
	    				for(j=0;j<=String(char_total).length-1;j++){
	    					l_total = l_total + Number(String(char_total)[j]);	
	    				}
	    				char_total = l_total;
	    				console.log(l_total);
	    				if ([11,22,33].includes(char_total)) {
	    	    			break;
	    	    		}
	    			}	
	    			arr.push(char_total);
	    		}
	    		
	    		let k;
	    		let total = 0;
	    		for(k=0;k<=arr.length-1;k++){
	    			total = total + arr[k];
	    		}
	    		while(total>9) {
	    			let j;
    				let l_total=0;
    				for(j=0;j<=String(total).length-1;j++){
    					l_total = l_total + Number(String(total)[j]);	
    				}
    				total = l_total;	
    				if ([11,22,33].includes(total)) {
    	    			break;
    	    		}
	    		}
	    		console.log(arr);
    			this.personalityNumber = total;
			});
    	},
    	calculateBalanceNumber() {
    		let birthday = this.birthday;
    		let fullname = this.removeVietnameseTones(this.fullname);
    		let birthdayArray = birthday.split('-');
    		let year = birthdayArray[0];
    		let month = birthdayArray[1];
    		let day = birthdayArray[2];
    		const data_map = { "a": 1, "j": 1, "s": 1, "b": 2, "k": 2, "t":2, "c":3, "l":3, "u":3, "d":4, "m":4, "v":4, "e":5, "n":5, "w":5, "f":6, "o":6, "x":6, "g":7, "p":7, "y":7, "h":8, "q":8, "z":8, "i":9, "r":9}
    		//const nguyemam_data_map = {"a":1,"u":3, "e":5, "o":6, "i":9}
    		let fullnameArray = fullname.split(" ")
    		let arr = []
    		fullnameArray.forEach((value, index) => {
	    		let i 
	    		let char_total = 0;
	    		if(value.length>0) {
	    			char_total = char_total + data_map[value[0]];
	    		}
	    		if ([11,22,33].includes(char_total)) {
	    			arr.push(char_total);
	    		} else {
	    			console.log(char_total);
	    			while(char_total>9) {
	    				console.log(char_total);
	    				console.log(String(char_total).length);
	    				let j;
	    				let l_total=0;
	    				for(j=0;j<=String(char_total).length-1;j++){
	    					l_total = l_total + Number(String(char_total)[j]);	
	    				}
	    				char_total = l_total;
	    				console.log(l_total);
	    				if ([11,22,33].includes(char_total)) {
	    	    			break;
	    	    		}
	    			}	
	    			arr.push(char_total);
	    		}
	    		
	    		let k;
	    		let total = 0;
	    		for(k=0;k<=arr.length-1;k++){
	    			total = total + arr[k];
	    		}
	    		while(total>9) {
	    			let j;
    				let l_total=0;
    				for(j=0;j<=String(total).length-1;j++){
    					l_total = l_total + Number(String(total)[j]);	
    				}
    				total = l_total;	
    				if ([11,22,33].includes(total)) {
    	    			break;
    	    		}
	    		}
	    		console.log(arr);
    			this.balanceNumber = total;
			});
    	},
    	calculateSoulNumber() {
        		let birthday = this.birthday;
        		let fullname = this.removeVietnameseTones(this.fullname);
        		console.log(fullname);
        		let birthdayArray = birthday.split('-');
        		let year = birthdayArray[0];
        		let month = birthdayArray[1];
        		let day = birthdayArray[2];
        		//const data_map = { "a": 1, "j": 1, "s": 1, "b": 2, "k": 2, "t":2, "c":3, "l":3, "u":3, "d":4, "m":4, "v":4, "e":5, "n":5, "w":5, "f":6, "o":6, "x":6, "g":7, "p":7, "y":7, "h":8, "q":8, "z":8, "i":9, "r":9}
        		const nguyemam_data_map = {"a":1,"u":3, "e":5, "o":6, "i":9,"y":7}
        		let fullnameArray = fullname.split(" ")
        		let arr = []
        		fullnameArray.forEach((value, index) => {
    	    		let i 
    	    		let char_total = 0;
    	    		
    	    		for(i=0;i<=value.length-1;i++){
    	    			if (["a", "u", "e", "o", "i","y"].includes(value[i])) {
    	    				char_total = char_total + nguyemam_data_map[value[i]];
    	    			}
    	    		}
    	    		
    	    		while(char_total>9) {
	    				console.log(char_total);
	    				console.log(String(char_total).length);
	    				let j;
	    				let l_total=0;
	    				for(j=0;j<=String(char_total).length-1;j++){
	    					l_total = l_total + Number(String(char_total)[j]);	
	    				}
	    				char_total = l_total;
	    				console.log(l_total);
	    				if ([11,22,33].includes(char_total)) {
	    	    			break;
	    	    		}
	    			}	
	    			arr.push(char_total);
    	    		
    			});
        		
        		let k;
	    		let total = 0;
	    		for(k=0;k<=arr.length-1;k++){
	    			total = total + arr[k];
	    		}
	    		while(total>9) {
	    			let j;
    				let l_total=0;
    				for(j=0;j<=String(total).length-1;j++){
    					l_total = l_total + Number(String(total)[j]);	
    				}
    				total = l_total;	
    				if ([11,22,33].includes(total)) {
    	    			break;
    	    		}
	    		}
	    		console.log(arr);
    			this.soulNumber = total;
        },
        
    	calculateMissionNumber() {
    		let birthday = this.birthday;
    		let fullname = this.removeVietnameseTones(this.fullname);
    		let birthdayArray = birthday.split('-');
    		let year = birthdayArray[0];
    		let month = birthdayArray[1];
    		let day = birthdayArray[2];
    		const data_map = { "a": 1, "j": 1, "s": 1, "b": 2, "k": 2, "t":2, "c":3, "l":3, "u":3, "d":4, "m":4, "v":4, "e":5, "n":5, "w":5, "f":6, "o":6, "x":6, "g":7, "p":7, "y":7, "h":8, "q":8, "z":8, "i":9, "r":9}
    		
    		let fullnameArray = fullname.split(" ")
    		let arr = []
    		fullnameArray.forEach((value, index) => {
	    		let i 
	    		let char_total = 0;
	    		
	    		for(i=0;i<=value.length-1;i++){
	    			char_total = char_total + data_map[value[i]];
	    		}
	    		
	    		if ([11,22,33].includes(char_total)) {
	    			arr.push(char_total);
	    		} else {
	    			console.log(char_total);
	    			while(char_total>9) {
	    				console.log(char_total);
	    				console.log(String(char_total).length);
	    				let j;
	    				let l_total=0;
	    				for(j=0;j<=String(char_total).length-1;j++){
	    					l_total = l_total + Number(String(char_total)[j]);	
	    				}
	    				char_total = l_total;
	    				console.log(l_total);
	    				if ([11,22,33].includes(char_total)) {
	    	    			break;
	    	    		}
	    			}	
	    			arr.push(char_total);
	    		}
	    		
	    		let k;
	    		let total = 0;
	    		for(k=0;k<=arr.length-1;k++){
	    			total = total + arr[k];
	    		}
	    		while(total>9) {
	    			let j;
    				let l_total=0;
    				for(j=0;j<=String(total).length-1;j++){
    					l_total = l_total + Number(String(total)[j]);	
    				}
    				total = l_total;	
    				if ([11,22,33].includes(total)) {
    	    			break;
    	    		}
	    		}
	    		console.log(arr);
    			this.missionNumber = total;
			});
    	},
    	
    	calculateLifePathNumber() {
    		let birthday = this.birthday;
    		let fullname = this.removeVietnameseTones(this.fullname);
    		let birthdayArray = birthday.split('-');
    		let year = birthdayArray[0];
    		let month = birthdayArray[1];
    		let day = birthdayArray[2];
    		const data_map = { "a": 1, "j": 1, "s": 1, "b": 2, "k": 2, "t":2, "c":3, "l":3, "u":3, "d":4, "m":4, "v":4, "e":5, "n":5, "w":5, "f":6, "o":6, "x":6, "g":7, "p":7, "y":7, "h":8, "q":8, "z":8, "i":9, "r":9}
    		const phuam_data_map = {"a":1,"u":3, "e":5, "o":6, "i":9}
    		let fullnameArray = fullname.split(" ")
    		//let lifePathNumberArray = []
    		//lifePathNumberArray.push(day)
    		//lifePathNumberArray.push(month)
    		//lifePathNumberArray.push(this.currentYear)
    		
    		let arr = []
    		birthdayArray.forEach((value, index) => {
	    		let i 
	    		let char_total = 0;
	    		
	    		for(i=0;i<=String(value).length-1;i++){
	    			char_total = char_total + Number(String(value)[i]);
	    			console.log(char_total);
	    		}
	    		while(char_total>9) {
	    				let j;
	    				let l_total=0;
	    				for(j=0;j<=String(char_total).length-1;j++){
	    					l_total = l_total + Number(String(char_total)[j]);	
	    				}
	    				char_total = l_total;
	    				if ([11,22,33].includes(char_total)) {
	    	    			break;
	    	    		}
	    		}	
	    		arr.push(char_total);
	    		
			});
    		console.log(arr)
    		let k;
    		let total = 0;
    		for(k=0;k<=arr.length-1;k++){
    			total = total + arr[k];
    		}
    		while(total>9) {
    			let j;
				let l_total=0;
				for(j=0;j<=String(total).length-1;j++){
					l_total = l_total + Number(String(total)[j]);	
				}
				total = l_total;	
				if ([11,22,33].includes(total)) {
	    			break;
	    		}
    		}
    		
    		
    		this.lifePathNumber = total;
    		
    	},
    	
    	async registerObject() {
    		let fullname = this.$refs.fullname.value;
    		let phone = this.$refs.phone.value;
    		let gender = this.$refs.gender.value;
    		let email = this.$refs.email.value;
    		
    		if (fullname.length < 2) {
    			alert("Nhập Họ Tên");
    			return;
    		}
    		if (phone.length < 10) {
    			alert("Nhập Số Điện Thoại");
    			return;
    		}
    		
    		const user = JSON.parse(localStorage.getItem("user"));
    		
    		const formData = {
    			id:this.object_registration_id,
     			fullname: this.$refs.fullname.value||"undefined",
    			email: this.$refs.email.value||"undefined",
    			phone: this.$refs.phone.value||"undefined",
    			content_type: this.$refs.content_type.value||44,
    			object_id: this.$refs.object_id.value||0,
    			note:this.$refs.note.value||"",
    			extra_data:{"gender": this.$refs.gender.value,"recommend_phone": this.$refs.recommend_phone.value}
        	}
    		if (this.object_id==null) {
    			await axios
                .put("/api/objectregistrations/"+this.object_registration_id+"/", formData)
                .then(response => {
                	//console.log(response.data);
                	this.object_registration_id = response.data.id;
                	this.object_id = response.data.object_id;
                	this.content_type = response.data.content_type;
                	$('#popupConsultant').modal('hide');
                	$('#message').show();
                	//this.$store.commit('showToast', "Registered Successfully");
                	//window.location.href = '/numerology/?fullname='+fullname+'&birthday='+birthday+'&phone='+phone+'&email='+email+'&gender='+gender+'&object_registration_id='+this.object_registration_id+'&content_type='+response.data.content_type+'&object_id='+response.data.object_id;
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            //this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        //this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    		} else {
        		await axios
                .post("/api/objectregistrations/", formData)
                .then(response => {
                	//console.log(response.data);
                	this.object_registration_id = response.data.id;
                	this.object_id = response.data.object_id;
                	this.content_type = response.data.content_type;
                	$('#popupConsultant').modal('hide');
                	$('#message').show();
                	//this.$store.commit('showToast', "Registered Successfully");
                	//window.location.href = '/numerology/?fullname='+fullname+'&birthday='+birthday+'&phone='+phone+'&email='+email+'&gender='+gender+'&object_registration_id='+this.object_registration_id+'&content_type='+response.data.content_type+'&object_id='+response.data.object_id;
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            //this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        //this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    		}
    	},
    	async registerBusiness() {
    		let fullname = this.$refs.biz_fullname.value;
    		let phone = this.$refs.biz_phone.value;
    		let gender = this.$refs.biz_gender.value;
    		let email = this.$refs.biz_email.value;
    		
    		if (fullname.length < 2) {
    			alert("Nhập Họ Tên");
    			return;
    		}
    		if (phone.length < 10) {
    			alert("Nhập Số Điện Thoại");
    			return;
    		}
    		
    		const user = JSON.parse(localStorage.getItem("user"));
    		const formData = {
    			id:this.object_registration_id,
     			fullname: this.$refs.biz_fullname.value||"undefined",
    			email: this.$refs.biz_email.value||"undefined",
    			phone: this.$refs.biz_phone.value||"undefined",
    			content_type: this.$refs.biz_content_type.value||44,
    			object_id: this.$refs.biz_object_id.value||0,
    			note:this.$refs.biz_note.value||"",
    			extra_data:{"gender": this.$refs.biz_gender.value,"recommend_phone": this.$refs.biz_recommend_phone.value}
        	}
        	console.log(formData)
        	if (this.object_id==null) {
        		await axios
                .put("/api/objectregistrations/"+this.object_registration_id+"/", formData)
                .then(response => {
                	console.log(response.data);
                	this.object_registration_id = response.data.id;
                	this.object_id = response.data.object_id;
                	this.content_type = response.data.content_type;
                	$('#popupBusiness').modal('hide');
                	$('#message').show();
                	//this.$store.commit('showToast', "Registered Successfully");
                	
                	//window.location.href = '/numerology/?fullname='+fullname+'&birthday='+birthday+'&phone='+phone+'&email='+email+'&gender='+gender+'&object_registration_id='+this.object_registration_id+'&content_type='+response.data.content_type+'&object_id='+response.data.object_id;
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
        	} else {
        		await axios
                .post("/api/objectregistrations/", formData)
                .then(response => {
                	console.log(response.data);
                	this.object_registration_id = response.data.id;
                	this.object_id = response.data.object_id;
                	this.content_type = response.data.content_type;
                	$('#popupBusiness').modal('hide');
                	$('#message').show();
                	//this.$store.commit('showToast', "Registered Successfully");
                	//window.location.href = '/numerology/?fullname='+fullname+'&birthday='+birthday+'&phone='+phone+'&email='+email+'&gender='+gender+'&object_registration_id='+this.object_registration_id+'&content_type='+response.data.content_type+'&object_id='+response.data.object_id;
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
        	}
    	},
        onCancel() {
        	console.log('User cancelled the loader.')
        },
        async getPage() {
            const pageSlug = this.$route.params.page_slug
			let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    onCancel: this.onCancel,
                    loader:'dots',
                });

            axios
                .get(`/api/pages/${pageSlug}/`)
                .then(response => {
                    this.page = response.data
                    document.title = this.page.title
                    setTimeout(() => {
	                    loader.hide()
	                }, 1000)
                })
                .catch(error => {
                    console.log(error)
                })

        }
    }
}
</script>
<style>
	img.wp-smiley, img.emoji {
	    display: inline !important;
	    border: none !important;
	    box-shadow: none !important;
	    height: 1em !important;
	    width: 1em !important;
	    margin: 0 0.07em !important;
	    vertical-align: -0.1em !important;
	    background: none !important;
	    padding: 0 !important;
	}
	.bSe p, .mry .awr-e p {
    	color: #666 !important;
	}
	.bSe h2 {
    	color: #464545 !important;
    	margin-bottom: 40px !important;
    	line-height: 35px !important;
	}
	.bSe a, .cnt a {
   	 	color: #f44336 !important;
	}
	.cnt .bSe {
    	color: #666 !important;
	}
	.bSe a {
	    clear: right !important;
	}
	.bSe h3 {
    	font-weight: 500 !important;
    	margin-bottom: 35px !important;
	    line-height: 30px !important;
	    padding-top: 30px !important;
	    color: #333 !important;
	}
	.bSe h4,.bSe h2,.bSe span {
		color: black !important;		
	}
	.bSe .cta .form-btn {
    	margin-top: 20px !important;
	    font-size: 15px !important;
	    padding-left: 15px !important;
	}
	
	.tcb-icon {
		color: green;
	    display: inline-block;
	    width: 1em;
	    height: 1em;
	    line-height: 1em;
	    vertical-align: middle;
	    stroke-width: 0;
	    stroke: currentColor;
	    fill: currentColor;
	    -webkit-box-sizing: content-box;
	    box-sizing: content-box;
	    -webkit-transform: rotate(var(--tcb-icon-rotation-angle, 0deg));
	    -ms-transform: rotate(var(--tcb-icon-rotation-angle, 0deg));
	    transform: rotate(var(--tcb-icon-rotation-angle, 0deg));
	}
	.thrv-styled-list-item span {
		color:black;
	}
	
	.image-nam-ca-nhan h2,.image-nam-ca-nhan h4 {
		color: black !important;
	}
	
	.bSe ul li, .bSe ol li { 
		color: black !important;
		text-align: left !important;
	}
	
	/* mandala */
	#mandala span {
	  display: block;
	  height: 20em;
	  left: calc(50% - 10em);
	  position: absolute;
	  top: calc(50% - 10em);
	  width: 20em;
	}
	
	#mandala .large-circles {
	  animation: spin 10s linear infinite;
	}
	
	#mandala .small-shapes {
	  animation: spin 30s linear infinite;
	}
	
	#mandala .content-squircle {
	  animation: spin 30s linear infinite;
	}
	
	@keyframes spin {
	  0% {
	    transform: rotateZ(0);
	  }
	  100% {
	    transform: rotateZ(360deg);
	  }
	}
	
	#mandala .circle,
	#mandala .squircle {
	  border-top: 0.1em solid rgba(255, 255, 255, 0.4);
	  height: 12.5em;
	  position: absolute;
	  width: 12.5em;
	}
	
	#mandala .circle {
	  border-radius: 50%;
	  box-shadow: 0 1em 2em rgba(0, 0, 0, 0.5);
	  left: calc(50% - 6.25em);
	  top: calc(50% - 12.5em);
	  transform-origin: 50% 12.5em;
	}
	
	#mandala .one {
	  background: rgba(16, 63, 236, 0.75);
	  transform: rotateZ(225deg);
	}
	
	#mandala .two {
	  background: rgba(37, 172, 162, 0.75);
	  transform: rotateZ(180deg);
	}
	
	#mandala .three {
	  background: rgba(233, 124, 32, 0.75);
	  transform: rotateZ(135deg);
	}
	
	#mandala .four {
	  background: rgba(235, 67, 35, 0.75);
	  transform: rotateZ(90deg);
	}
	
	#mandala .five {
	  background: rgba(190, 28, 65, 0.75);
	  transform: rotateZ(45deg);
	}
	
	#mandala .six {
	  background: rgba(208, 57, 159, 0.75);
	  transform: rotateZ(0);
	}
	
	#mandala .seven {
	  background: rgba(150, 32, 198, 0.75);
	  transform: rotateZ(-45deg);
	}
	
	#mandala .eight {
	  background: rgba(95, 33, 203, 0.75);
	  transform: rotateZ(-90deg);
	}
	
	#mandala .small {
	  width: 4em;
	  height: 4em;
	  left: calc(50% - 2em);
	  top: calc(50% - 15em);
	  transform-origin: 50% 15em;
	  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.2);
	}
	
	#mandala .small.squircle {
	  background: none;
	  border: none;
	  box-shadow: none;
	}
	
	#mandala .small.squircle::after {
	  background: red;
	  border-radius: 25%;
	  box-shadow: -0.25em 0.25em 0.5em rgba(0, 0, 0, 0.2);
	  content: "";
	  height: 100%;
	  position: absolute;
	  transform: rotateZ(-45deg);
	  width: 100%;
	}
	
	#mandala .small.squircle.two::after {
	  background: rgba(37, 172, 162, 0.75);
	}
	
	#mandala .small.squircle.four::after {
	  background: rgba(235, 67, 35, 0.75);
	}
	
	#mandala .small.squircle.six::after {
	  background: rgba(208, 57, 159, 0.75);
	}
	
	#mandala .small.squircle.eight::after {
	  background: rgba(95, 33, 203, 0.75);
	}
	
	#mandala .large.squircle {
	  background: rgba(30, 7, 66, 0.15);
	  border: none;
	  height: 15em;
	  width: 15em;
	  left: calc(50% - 7.5em);
	  position: absolute;
	  top: calc(50% - 7.5em);
	  transform: none;
	  border-radius: 2em;
	  border-bottom: 2em;
	}
</style>
<template>
	<!--Section Top-->
			<section class="ds section-home s-py-90 s-pb-lg-80 s-pt-xl-150 s-pb-xl-140">
				<div class="container">
					<div class="divider-50 divider-xl-65"></div>
					<div class="row align-center">
						<div class="col-lg-6 col-xl-5 order-lg-1 order-2 text-center text-md-left">
							<div class="divider-60 divider-lg-0"></div>
							<h5 class="special-heading bold mb-2 text-white">
								<span>TRA CỨU THẦN SỐ HỌC</span>
							</h5>
							<p>
								Giải mã tiềm năng và làm chủ cuộc sống của bạn thông qua Thần số học (hay còn được gọi nhân số học) để khám phá bạn là ai và dự đoán tương lai của bạn thông qua năng lượng của các con số!
							</p>
							<div class="divider-35 divider-xl-55"></div>
							<div class="line line-left"></div>
							<div class="divider-40 divider-xl-60"></div>
							<h3 class="special-heading fw-500 mb-2 color-main2 big-size">
								<span>Giải Mã</span>
							</h3>
							<h3 class="special-heading fw-500 text-white fs-50">
								<span>Tín Hiệu Từ Vũ Trụ</span>
							</h3>
							<div class="divider-35 divider-xl-65"></div>
							<a href="/#faq" class="btn btn-maincolor">Bắt Đầu Ngay</a>
						</div>
						<div class="col-lg-6 col-xl-7 order-lg-2 order-1 text-center text-lg-right">
							<img class="horoscope-image" src="../assets/images/horoscope.png" alt="img">
						</div>
					</div>
				</div>
			</section>
	<!--Section About-->
			<section id="about" class="ds s-py-90 s-py-xl-150 c-gutter-50">
				<div class="container">
					<div class="row align-center">
						<div class="col-lg-6">
							<img src="../assets/images/about-img.png" alt="img">
						</div>
						<div class="col-lg-6 text-center text-md-left">
							<div class="divider-60 divider-lg-0 "></div>
							<p class="text-white text-center text-md-left fs-20 line-right mb-0">
								<span>Giới Thiệu</span>
							</p>
							<h3 class="special-heading mt-2 fs-60 text-center text-md-left">
								<span>THẦN SỐ HỌC LÀ GÌ?</span>
							</h3>
							<div class="divider-35"></div>
							<p>
								Các con số ở khắp mọi nơi và một khi bạn biết cách chúng hoạt động trong cuộc sống, rất có thể bạn sẽ không bao giờ nhìn lại các con số theo cùng một cách nữa. Thần số học ( hay còn được gọi là nhân số học ) là một công cụ tuyệt vời để thêm vào các kỹ năng sống của bạn.
							</p>
							<p>
								Thần số học được hình thành và phát triển vào khoảng năm 530 trước Công nguyên. Nhà toán học Pythagoras – người sáng lập ra Định lý hình học Pitago đã cho rằng mỗi con số không chỉ đại diện cho một số lượng (ví dụ: 1 quả táo, 2 quả táo…), mà nó còn mang một tầng ý nghĩa về sự rung động, về các tần số trong cuộc sống có ảnh hưởng cụ thể tới con người”.
							</p>
							<div class="divider-30 divider-xl-50"></div>
							<a href="#faq" class="btn btn-outline-maincolor btn-medium">Tra Cứu Ngay</a>
						</div>
					</div>
				</div>
			</section>

			<!--Section Forecast-->
			<section id="forecasts" class="ds section-forecast s-overlay s-pt-90 s-pb-30 s-pt-xl-150 s-pb-xl-90 c-gutter-60 c-mb-60">
				<div class="container">
					<div class="row"  v-if="this.numerology_posts">
						<div class="col-12 text-center mb-0">
							<h3 class="text-center mt-0 mb-2 special-heading fs-60 color-main">
								<span>Con Số Trong Thần Số Học</span>
							</h3>
							<div class="line"></div>
							<p class="text-center fs-20 mt-4">
								<span>Giảm mã các con số trong thần số học</span>
							</p>

							<div class="divider-40 divider-xl-60"></div>
						</div>
						<div class="col-xl-3 col-lg-4 col-md-6" v-for="(object,index) in this.numerology_posts">
							<div class="vertical-item text-center  content-padding corner-box">
								<div class="item-content" style="text-align:center">
									<div class="icon-styled fs-50">
										<a :href="'/posts/'+object.slug+'/'" class="color-darkgrey ico">{{object.title.slice(-2) }}</a>
									</div>
									<h5 class="mt-4 mt-lg-5 fs-20 links-maincolor2">
										<a :href="'/posts/'+object.slug+'/'">{{object.title}}</a>
									</h5>
									<p>
									</p>
								</div>
							</div>
						</div><!-- .col-* -->
					</div>
				</div>
			</section>

			<!--Section Service-->
			<section id="services" class="ds section-service s-overlay mobile-overlay s-py-90 s-py-xl-150 container-px-xl-0">
				<div class="container-fluid">
					<div class="row">
						<div class="col-xl-5"></div>
						<div class="col-xl-3 col-lg-6">
							<p class="text-white text-center text-md-left fs-20 line-right mb-0">
								<span>Dịch Vụ</span>
							</p>
							<h3 class="special-heading mt-2 fs-60 text-center text-md-left">
								<span>Giải Mã <br>Tra Cứu</span>
							</h3>
						</div>
						<div class="col-xl-3 col-lg-6 text-center text-md-left">
							<p class="text-center text-md-left  mt-4 mt-lg-0">
								<span>Ngoài Giải Mã và Tra Cứu Thần Số Học thì Thần Số Việt Nam còn cung cấp các dịch vụ giải mã về Cung Hoàng Đạo, Bói Tình Yêu, Bói Bài Tarot, Tên Hay Cho Con…. Và nhiều dịch vụ khác nữa…</span>
							</p>
							<div class="divider-35"></div>
							<a href="/#faq" class="btn btn-outline-maincolor btn-medium">Tra Cứu Thần Số Học Pitago</a>
						</div>
					</div>
				</div>
			</section>

			<!-- Section Shop -->
			<section id="shop" class="ds section-shop s-pt-85 s-pb-60 s-pt-xl-145 s-pb-xl-120">
				<div class="container">
					<div class="row">
						<div class="col-xl-12 col-lg-12">
							<p class="text-white text-center text-md-left fs-20 line-right mb-0">
								<span>Hệ Thống</span>
							</p>
							<h3 class="special-heading mt-2 fs-60 text-center text-md-left">
								<span>Ứng Dụng Thần Số Học Online</span>
							</h3>
							<h4>Hệ Thống Ứng Dụng Thần Số Học Từ Xưa Đến Nay:</h4>
							<p class="mt-4 mt-lg-0">
								<span>Thần số học Pitago: Là một hệ thống được sử dụng rộng rãi hiện nay. Lưu ý rằng Pitago không sáng tạo ra bộ môn Thần số học mà chỉ là kế thừa và hoàn thiện. Ông khái niệm rằng: Mọi chữ số đều có thể rút gọn thành các chữ số đơn từ 1 đến 9. Mỗi chữ số đều ẩn chứa 1 năng lượng “kì dị” đến từ vũ trụ. Ông đã áp dụng điều này vào các giai đoạn trong vòng đời con người và nó chính xác đến lạ kỳ.</span>
							</p>
							<p>Thần số học Chaldean – Nhân số học cổ xưa nhất. Nó bắt nguồn từ Babylon cổ đại và dựa trên các con số từ 1 đến 8. Số 9 được coi là linh thiêng, tách biệt khỏi cộng đồng. Tuy nhiên hiện nay nó không được sử dụng rộng rãi và bạn không cần quan tâm đến nó.</p>
							<p>Hệ thống nhân số học Kabbalah: Đúng vậy, nó bắt nguồn từ tiếng Do thái và là hệ thần số tập trung chủ yếu và chữ viết của ngôn ngữ Do thái, nó có 22 kiểu rung động khác nhau. Có 1 hệ thống là: New Kabbalah Numerology được điều chỉnh bao gồm 26 chữ cái bà sử dụng biểu đồ số Pitago. Tuy nhiên, hệ thống này không sử dụng ngày sinh vì thế nên nó không phổ biến với các nước phương Tây.</p>
							<h4>Thần Số Học Của Thế Kỉ 21:</h4>
							<p class="mt-4 mt-lg-0">
							<span>Thần số học ứng dụng đã bị quên lãng cho đến khi Mrs Dow Balliett ở thành phố Atlantic giới thiệu bộ môn Thần số cho thế giới các nước phương Tây. Bà là một phần của Phong trào Tư tưởng Thời đại mới vào những năm 1900.</span>
							</p>
							<p>Những tư tưởng của bà dựa trên lý thuyết Số học của Pitago. Dow Balliett là người có ảnh hưởng đáng kể đến Tiến sĩ Julia Seton, người đã phổ biến hệ thống Thần số học hiện đại và khai sinh cho nó 1 cái tên là: “Numerology”</p>
							<ul>
							<li>Con số đường đời: Con số đường đời là con thể hiện những tính cách ẩn sâu trong bạn được tính qua ngày tháng năm sinh của bạn. Đây chính là con số chủ đạo của bạn trong Thần số học. Con số này còn được gọi là Con Số Life Path</li>
							<li>Thần số học ứng dụng: Có lẽ là cuốn sách hay nhất về Thần Số Học giúp bạn rèn luyện trực giác của bản thân tốt hơn. Từ đó có cái nhìn hiểu rõ hơn những người xung quan mình.</li>
							<li>Cách tính Thần Số Học: Dựa vào họ tên và ngày tháng năm sinh của bạn để có thể quy đổi ra các con số rồi cộng chúng lại với nhau. Từ đó ta có được con số chủ đạo của bạn….</li>
							<li>Chỉ số linh hồn: Chỉ số linh hồn là con số được thể hiện qua các cảm xúc, giác quan và khát vọng về nội tâm bên trong bạn…</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section id="faq" class="ds s-py-90 s-pt-xl-145 s-pb-xl-130">
				<div class="container">
					<div class="row">
						<div class="col-12 text-center">
							<h3 class="text-center mt-0 mb-2 special-heading fs-60 color-main">
								<span>Tra Cứu</span>
							</h3>
							<div class="line"></div>
							<p class="text-center fs-20 mt-4">
								<span>Tra Cứu Thần Số Học Pitago Miễn Phí</span>
							</p>
							<div class="divider-40 divider-xl-60"></div>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-8 offset-lg-2 animate animated scaleAppear" data-animation="scaleAppear">
							<div class="inner-wrap ds bordered box-shadow p-30 p-xl-40">
								<div class="contact-form c-mb-10 c-gutter-10">
									<div class="divider-20"></div>
									<div class="row">

										<div class="col-sm-12">
											<div class="form-group has-placeholder">
												<label for="fullname">Họ tên <span class="required">*</span></label>
												<input type="text" aria-required="true" size="30" value="" name="fullname" id="fullname" ref="fullname" class="form-control" placeholder="Họ tên">
											</div>
										</div>
										<div class="col-sm-6">
											<div class="form-group">
								                <label class="text-left" for="birthday">Ngày tháng năm sinh</label>
								                <input required="" type="date" name="birthday" id="birthday" ref="birthday" class="form-control" placeholder="Ngày tháng năm sinh" min="1900-04-01" max="2023-01-01">
								            </div>
							            </div>
							            <div class="col-sm-6">
							            	<div class="form-group">
								                <label class="text-left" for="gender">Giới tính</label>
								                <select name="gender" class="form-control" id="gender" ref="gender">
								                    <option selected="" value="0">Nam</option>
								                    <option value="1">Nữ</option>
								                </select>
								            </div>
							            </div>
										<div class="col-sm-6">
											<div class="form-group has-placeholder">
												<label for="email">Email<span class="required">*</span></label>
												<input type="email" aria-required="true" size="30" value="" name="email" id="email" ref="email" class="form-control" placeholder="Email">
											</div>
										</div>
										<div class="col-sm-6">
											<div class="form-group has-placeholder">
												<label for="phone">Điện thoại<span class="required">*</span></label>
												<input type="text" aria-required="true" size="30" value="" name="phone" id="phone" ref="phone" class="form-control" placeholder="Phone">
											</div>
										</div>

									</div>

									<div class="row">

										<div class="col-sm-12 mt-xl-30 mt-20">
											<div class="form-group">
												<button type="submit" v-on:click="numberology()" id="submit" ref="submit" name="submit" class="btn btn-maincolor btn-medium">Xem Thần Số Học Của Bạn
												</button>
											</div>
										</div>
									</div>
									<div class="divider-20"></div>
								</div>
							</div>
						</div>
						<!--.col-* -->
					</div>
				</div>
			</section>

			<!-- Section Blog -->
			<section id="blog" class="ds bs pattern s-py-90 s-py-xl-150">
				<div class="container">
					<div class="row" v-if="this.handbook_posts">
						<div class="col-xl-5 col-lg-6">
							<p class="text-white text-center text-md-left fs-20 line-right mb-0">
								<span>Cẩm Nang</span>
							</p>
							<h3 class="special-heading mt-2 fs-60 text-center text-md-left">
								<span>Kiến Thức Thần Số Học</span>
							</h3>
						</div>
						<div class="col-xl-7 col-lg-6 text-center text-md-left">
							<p class="text-center text-md-left  mt-4 mt-lg-0">
								<span>Tìm hiểu kiến thức về thần số học</span>
							</p>
							<div class="divider-35"></div>
							<a href="/posts/type/handbook/" class="btn btn-outline-maincolor btn-medium">Tìm hiểu ngay</a>
						</div>
						<div class="col-12">
							<div class="divider-55"></div>
							<div class="shortcode-post-grid-tilled-1">
								<div class="special-img">
									<img src="../assets/images/gallery/22.jpg" alt="img">
								</div>
								<article v-for="object in this.handbook_posts" class="vertical-item text-center text-md-left post type-post status-publish format-standard has-post-thumbnail mt-0">
									<div class="item-content">
										<header class="entry-header">
											<h4 class="entry-title mb-3 links-maincolor2">
												<a class="fs-30" :href="'/posts/'+object.slug+'/'" rel="bookmark">
													{{object.title}}
												</a>
											</h4>
										</header>
										<!-- .entry-header -->
										<div class="entry-content mt-20">
											<p>
											</p>
										</div><!-- .entry-content -->
										<div class="entry-footer flex-row mt-4">
											<div class="entry-meta">
												<span>
													<a :href="'/posts/'+object.slug+'/'" rel="bookmark">
														<time class="entry-date published updated" :datetime="object.created_at">{{getFormattedDate(object.created_at)}}</time>
													</a>
												</span>
											</div>
											<!-- .entry-meta -->
											<div class="mt-0 ml-auto">
												<a :href="'/posts/'+object.slug+'/'" class="mt-30 font-2">Đọc thêm <i class="fa fa-caret-right color-main ml-2"></i></a>
											</div>
										</div>
									</div><!-- .item-content -->
								</article><!-- #post-## -->
							</div>
							<div class="divider-60"></div>
						</div>
						<div class="col-12 text-center">
							<div class="divider-55 divider-xl-115"></div>
							<h3 class="text-center mt-0 mb-2 special-heading fs-60 color-main">
								<span>Hỏi Đáp</span>
							</h3>
							<div class="line"></div>
							<p class="text-center fs-20 mt-4">
								<span>Về tra cứu thần số học Pitago Online Miến Phí</span>
							</p>

							<div class="divider-40 divider-xl-60"></div>
							<div v-if="this.faq_posts" class="faq-item" v-for="object in this.faq_posts">
								<h4>{{object.title}}</h4>
								<p>{{object.description}}</p>
								<template v-html="object.content"></template>
							</div>
							<button type="submit" id="contact_form_submit" name="contact_submit" class="btn btn-maincolor btn-medium">Xem thêm</button>
						</div>
					</div>
				</div>
			</section>	
</template>

<script>
import DataAPI from '@/apis/data'
import LayoutHome from '../layouts/LayoutHome.vue';
import axios from 'axios';
import moment from 'moment';
//import 'vue3-carousel/dist/carousel.css';
//import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
export default {
  name: 'Home',
  components: {
  	//Carousel,
    //Slide,
    //Pagination,
    //Navigation, 
  },
  data() {
    return {
      settings: {
      itemsToShow: 5,
      snapAlign: 'center',
    },
      breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 5,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
    },
      fullPage: false,
      rate_amount:1,
      api_baseurl:"",
      classes:null,
      posts:null,
      miscData:null,
      items:null,
      galleries:null,
      sliders:null,
      banners:null,
      numerology_posts:null,
      handbook_posts:null,
      faq_posts:null,
      content_type:null,
      object_id:null,
    }
  },
  filters: {
    toCurrency(value) {
        if (typeof value !== "number") {
            return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        });
        return formatter.format(value);
    }
  },
  beforeCreate() {
  	console.log("beforeCreate");
  },
  created() {
  },
  methods: {
	  getFormattedDate(date) {
          return moment(date).format("YYYY-MM-DD")
      },
	getNumerology() {
		DataAPI.getPosts({type:1,page_size: 13 }).then(res => {
		     this.numerology_posts=res.data.results
		})	
	},
	getHandbook() {
		DataAPI.getPosts({type:2,page_size: 2 }).then(res => {
		     this.handbook_posts=res.data.results
		})	
	},
	getFaq() {
		DataAPI.getPosts({type:4,page_size: 2 }).then(res => {
		     this.faq_posts=res.data.results
		})	
	},
	async numberology() {
		let birthday = this.$refs.birthday.value;
		let fullname = this.$refs.fullname.value;
		let phone = this.$refs.phone.value;
		let gender = this.$refs.gender.value;
		let email = this.$refs.email.value;
		
		if (birthday.length < 10) {
			alert("Nhập Ngày Sinh");
			return;
		}
		if (fullname.length < 2) {
			alert("Nhập Họ Tên");
			return;
		}
		if (phone.length < 10) {
			alert("Nhập Số Điện Thoại");
			return;
		}
		
		let birthdayArray = birthday.split('-');
		let year = birthdayArray[0];
		let month = birthdayArray[1];
		let day = birthdayArray[2];
		
		const user = JSON.parse(localStorage.getItem("user"));
		const formData = {
 			fullname: this.$refs.fullname.value||"undefined",
			email: this.$refs.email.value||"undefined",
			phone: this.$refs.phone.value||"undefined",
			birthday:  moment(this.$refs.birthday.value).format(),
			note:"",
			extra_data:{"gender": this.$refs.gender.value}
    	}
    	console.log(formData)
    	await axios
            .post("/api/objectregistrations/", formData)
            .then(response => {
            	console.log(response.data);
            	this.object_registration_id = response.data.id;
            	this.$store.commit('showToast', "Registered Successfully");
            	//window.location.href = '/numerology/?fullname='+fullname+'&birthday='+birthday+'&phone='+phone+'&email='+email+'&gender='+gender+'&object_registration_id='+this.object_registration_id;
            	window.location.href = '/numerology/?fullname='+fullname+'&birthday='+birthday+'&phone='+phone+'&email='+email+'&gender='+gender+'&object_registration_id='+this.object_registration_id+'&content_type='+response.data.content_type+'&object_id='+response.data.object_id;
            })
            .catch(error => {
                if (error.response) {
                    for (const property in error.response.data) {
                        //this.errors.push(`${property}: ${error.response.data[property]}`)
                        this.$store.commit('showToast', error.response.data[property]);
                    }
                } else {
                    //this.errors.push('Something went wrong. Please try again')
                    this.$store.commit('showToast', 'Something went wrong. Please try again');
                    console.log(JSON.stringify(error))
                }
            })
	},
	async objectRegistration() {
		
		const user = JSON.parse(localStorage.getItem("user"));
		const formData = {
 			fullname: this.$refs.fullname.value||"undefined",
			email: this.$refs.email.value||"undefined",
			phone: this.$refs.phone.value||"undefined",
			birthday: this.$refs.birthday.value||"undefined",
			gender: this.$refs.gender.value||"undefined",
			status:'created',
			user:user.id,
    	}
    	console.log(formData)
    	await axios
            .post("/api/objectregistrations/", formData)
            .then(response => {
            	console.log(response.data);
            	this.object_registration_id = response.data.id;
            	this.content_type = response.data.content_type;
            	this.object_id = response.data.object_id;
            	this.$store.commit('showToast', "Registered Successfully");
            	window.location.href = '/numerology/?fullname='+fullname+'&birthday='+birthday+'&phone='+phone+'&email='+email+'&gender='+gender+'&object_registration_id='+this.object_registration_id+'&content_type='+this.content_type+'&object_id='+object_id;
            })
            .catch(error => {
                if (error.response) {
                    for (const property in error.response.data) {
                        //this.errors.push(`${property}: ${error.response.data[property]}`)
                        this.$store.commit('showToast', error.response.data[property]);
                    }
                } else {
                    //this.errors.push('Something went wrong. Please try again')
                    this.$store.commit('showToast', 'Something went wrong. Please try again');
                    console.log(JSON.stringify(error))
                }
            })
	},
    doAjax() {
        this.isLoading = true;
        // simulate AJAX
        setTimeout(() => {
        	this.isLoading = false
    	}, 5000)
    },
    formatPrice(value) {
        //let val = (value/1)
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    goto(url){
		window.location.href = url
	},
	
 },
 mounted() {
    document.title = 'Home'
    this.api_baseurl= axios.defaults.baseURL
    if(this.sliders == null) {
    	this.sliders  = JSON.parse(localStorage.getItem("sliders"));
    }
    if(this.banners == null) {
    	this.banners  = JSON.parse(localStorage.getItem("banners"));
    }
    this.getNumerology();
    this.getHandbook();
    this.getFaq();
  },
}

</script>
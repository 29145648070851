<template>
	<section class="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65">
				<div class="container">
					<div class="row">

						<div class="col-md-12">
							<h1>Liên Hệ</h1>
							<ol class="breadcrumb">
								<li class="breadcrumb-item">
									<a href="/">Trang Chủ</a>
								</li>
								<li class="breadcrumb-item">
									<a href="#">Pages</a>
								</li>
								<li class="breadcrumb-item active">
									Liên Hệ
								</li>
							</ol>
						</div>

					</div>
				</div>
	</section>
	<section class="ds bs s-py-90 s-py-xl-150">
				<div class="container">
					<div class="row">
						<div class="col-sm-4 animate" data-animation="pullDown">
							<div class="icon-box text-center">
								<div class="icon-styled color-main fs-56 mb-20">
									<i class="fa fa-phone"></i>
								</div>
								<p>
									<strong>Phone:</strong> 0854919891
								</p>
							</div>
							<div class="divider-30 divider-lg-0"></div>
						</div>

						<div class="col-sm-4 animate" data-animation="pullDown">
							<div class="icon-box text-center">
								<div class="icon-styled color-main fs-56 mb-20">
									<i class="fa fa-map-marker"></i>
								</div>
								<p>
									72 DT766, Đức Hạnh, Đức Linh, Bình Thuận
								</p>
							</div>
							<div class="divider-30 divider-lg-0"></div>
						</div>

						<div class="col-sm-4 animate" data-animation="pullDown">
							<div class="icon-box text-center">
								<div class="icon-styled color-main fs-56 mb-20">
									<i class="fa fa-envelope"></i>
								</div>
								<p>
									<a href="mailto:rain@semode.com"><span>rain@semode.com</span></a>
								</p>
							</div>
						</div>
					</div>

					<div class="row">

						<div class="divider-60 divider-xl-100"></div>

						<div class="col-lg-12 animate" data-animation="scaleAppear">

							<div class="ds bordered box-shadow  p-lg-60 p-40">

								<form class="contact-form c-mb-10 c-gutter-10" method="post" action="/">

									<div class="row">

										<div class="col-sm-6">
											<div class="form-group has-placeholder">
												<label for="name">Full Name <span class="required">*</span></label>
												<input type="text" aria-required="true" size="30" value="" name="name" id="name" class="form-control" placeholder="Full Name">
											</div>
											<div class="form-group has-placeholder">
												<label for="email">Email address<span class="required">*</span></label>
												<input type="email" aria-required="true" size="30" value="" name="email" id="email" class="form-control" placeholder="Email">
											</div>
											<div class="form-group has-placeholder">
												<label for="subject">Subject<span class="required">*</span></label>
												<input type="text" aria-required="true" size="30" value="" name="subject" id="subject" class="form-control" placeholder="Subject">
											</div>
										</div>
										<div class="col-sm-6">

											<div class="form-group has-placeholder">
												<label for="message">Message</label>
												<textarea aria-required="true" rows="6" cols="45" name="message" id="message" class="form-control" placeholder="Message"></textarea>
											</div>
										</div>
									</div>
									<div class="row mt-20 mt-xl-40">
										<div class="col-sm-12">
											<div class="form-group text-center">
												<button type="submit" id="contact_form_submit" name="contact_submit" class="btn btn-maincolor btn-medium">Send Message
												</button>
											</div>
										</div>

									</div>
								</form>

							</div>

						</div>
						<!--.col-* -->

					</div>
				</div>
	</section>
</template>
<template>
  <component :is="layout"> 
  	<router-view/> 
  </component>
</template>

<script setup>

import { defineAsyncComponent, computed } from 'vue'
import { useRoute } from 'vue-router';
let layout = computed( () => { 
        const { meta } = useRoute();
        return defineAsyncComponent( () => import(`./layouts/LayoutHome.vue`) )
        try{
        	console.log(meta.layout.name);
        	return defineAsyncComponent( () => import(`./layouts/${meta.layout.name ?? 'LayoutDefault'}.vue`) )
        } catch (e) {
        	return defineAsyncComponent( () => import(`./layouts/LayoutDefault.vue`) )
        }
    }
)
</script>

<script>
import axios from 'axios'
import DataAPI from '@/apis/data'
export default {
  data() {
    return {
      biz_resp_data: "",
      metamask_address: "",
      msg: "This is demo net work",
      rate_amount:1,
      api_baseurl:"",
    }
  },
  filters: {
    toCurrency(value) {
        if (typeof value !== "number") {
            return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        });
        return formatter.format(value);
    }
  },
  mounted() {
  },
  beforeCreate() {
  	console.log("beforeCreate");
  },
  methods: {
    doAjax() {
        this.isLoading = true;
        // simulate AJAX
        setTimeout(() => {
        	this.isLoading = false
    	}, 5000)
    },
    onCancel() {
    	console.log('User cancelled the loader.')
    },
    formatPrice(value) {
        //let val = (value/1)
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    goto(url){
		window.location.href = url
	},
 },
  mounted() {
    document.title = 'Home'
    this.api_baseurl= axios.defaults.baseURL
  },
  computed: {
  		toast() {
  			return this.$store.getters.toast;
  		}
 	},
}

</script>
<template>

</template>

<script>
import axios from 'axios'
import config from '@/config.js';
import moment from 'moment';
import LocaleSwitcher from "@/components/LocaleSwitcher";
export default {
  name: "LayoutItem",
  components: {
	  LocaleSwitcher
  },
  data() {
    return {
      showMobileMenu: false,
      cart: {},
      isAuthenticated:false,
      accessToken:"",
      refreshToken:"",
      copyright_year: moment(new Date()).format('YYYY'),
      site_name: "Mandala House",
      miscData: null,
    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')
    this.accessToken = JSON.parse(localStorage.getItem("accessToken"));
    
    const token = this.$store.state.token
    if (token) {
        //axios.defaults.headers.common['Authorization'] = "Token " + token
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
    } else {
        axios.defaults.headers.common['Authorization'] = ""
    }
  },
  mounted() {
  	this.cart = JSON.parse(localStorage.getItem("cart"));
  	this.isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));
    this.miscData = JSON.parse(localStorage.getItem("miscData"))
   },
  methods: {
    async signup() {
    		axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            
    		const formData = {
            	user: {
			        full_name: accounts[0],
			    },
                public_address: accounts[0]
            }

            await axios
                .post("/api/metamask/", formData)
                .then(response => {
                    $('#connectWalletModal').modal('toggle');
                    const token = response.data.access
                    this.$store.commit('setToken', token)
                    this.$store.commit('showToast', 'Login successfully!');
                    
                    //axios.defaults.headers.common["Authorization"] = "Token " + token
                    axios.defaults.headers.common['Authorization'] = "Bearer " + token
                    this.$store.commit('setIsWalletConnected',true);
					this.$store.commit('setIsAuthenticated',true);
					this.$store.commit('setUser',response.data.user)
					//localStorage.setItem("user", response.data.user)
					
                    const toPath = this.$route.query.to || '/'
                    this.$router.push(toPath)
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${error.response.data[property]}`)
                            this.$store.commit('showToast', `${error.response.data[property]}`);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    	},
    	
    	async login(nonce) {
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            //let msg = web3.utils.keccak256('Hello!');
            let public_address =  accounts[0];
            let msg = 'I am signing my one-time nonce '+nonce
            let sign = await web3.eth.personal.sign(msg, public_address);
            
            //const sign=web3.personal.sign(web3.utils.toUtf8("Hello!"), accounts[0], console.log);
            //console.log(sign)
            
            const formData = {
            	signature:sign,
            }
			
            await axios
                .post("/api/metamask/login/"+public_address, formData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                    $('#connectWalletModal').modal('toggle');
                    
                    const token = response.data.access
                    this.$store.commit('setToken', token)
                    this.$store.commit('showToast', 'Login successfully!');
                    
                    //axios.defaults.headers.common["Authorization"] = "Token " + token
					axios.defaults.headers.common['Authorization'] = "Bearer " + token
                    
                    this.$store.commit('setIsWalletConnected',true);
					this.$store.commit('setIsAuthenticated',true);
					this.$store.commit('setUser',response.data.user)
                    this.$store.commit('setRefreshToken',response.data.refresh)
                    this.$store.commit('setAccessToken',response.data.access)
                    
                    this.isWalletConnected = true;
                    this.$store.state.user = response.data.user
                    localStorage.setItem("token", token)
					this.$store.state.isWalletConnected=true;
					
                    
					//localStorage.setItem("user", response.data.user)
                    const toPath = this.$route.query.to || '/'
                    this.$router.push(toPath)
                })
                .catch(error => {
                	this.signup()
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${error.response.data[property]}`)
                            this.$store.commit('showToast', `${error.response.data[property]}`);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
        },
	async submitForm() {
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            //let msg = web3.utils.keccak256('Hello!');
            let public_address =  accounts[0];
            await axios.get("/api/metamask/"+public_address)
            .then(response => {
            	console.log(response.data.nonce) 	
            	this.login(response.data.nonce)
            	
            }).catch(error => {
            	this.signup()
            	console.log(error)
            })
    },
    async startLogin() {
    const publicAddress =this.metamask_address;
    
     if (window.ethereum) {
	  try {
	    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
	    if(accounts.length >0) {
	    		$('#connectWalletModal').modal('toggle');
	    		//localStorage.setItem("isWalletConnected",true);
	    		//this.$store.commit('setIsWalletConnected',true);
		        this.$store.commit('setIsWalletConnected',true);
				this.$store.commit('setIsAuthenticated',true);
				this.$store.commit('setUser',response.data.user)
				
	    	}
	  } catch (error) {
	    if (error.code === 4001) {
	      // User rejected request
	    }
	
	    console.error(error);
	  }
	  }
	},
    async disconnectWallet() {
    	console.log(web3);
    	console.log(web3.currentProvider);
		this.$store.commit('setIsWalletConnected',false);
		this.$store.commit('setIsAuthenticated',false);
		this.$store.commit('removeToken','');
		
		this.$store.commit('setMetamaskWallet',{});
		axios.defaults.headers.common["Authorization"] = "";
        localStorage.removeItem("token");
            
		/*
		await window.ethereum.request({
	        method: "wallet_requestPermissions",
	        params: [{
	            eth_accounts: {}
	        }]
	    });
	    */
    },    
    async connectWallet() {
	      try {
	        await ethereum.enable();
	        $('#connectWalletModal').modal('toggle');
			
			this.$store.commit('setIsWalletConnected',true);
			this.$store.commit('setIsAuthenticated',true);
			
	      } catch (error) {
	      	this.$store.commit('setIsWalletConnected',false);
			this.$store.commit('setIsAuthenticated',false);
	        this.Log(
	          this.MetamaskMsg.USER_DENIED_ACCOUNT_AUTHORIZATION,
	          "USER_DENIED_ACCOUNT_AUTHORIZATION"
	        );
	      }
	      
    },
  	
  	hideToast() {
  		this.$store.commit("hideToast");
  	},
	async getItemList() {
	  		await axios
	                .get("/api/items/?image_sizes=550x590")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setTrendingArtworks', response.data.results);
						//this.trendingArtworks=response.data.results
						//localStorage.setItem("trendingArtworks", response.data.results);
						//this.$store.state.trendingArtworks = response.data.results
						//localStorage.setItem("trendingArtworks", JSON.stringify(response.data.results));
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
  	async getCoinbase() {
    	await axios
                .get("https://api.coinbase.com/v2/exchange-rates?currency=MATIC")
                .then(response => {
					this.$store.commit('setRate', response.data.data);
					console.log(response.data.data.currency);
					console.log(response.data.data.rates);
					console.log(response.data.data.rates['ETH']);
					console.log(response.data.data.rates['BTC']);
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    },
  	async logout() {
  		console.log(localStorage.getItem("token"))
  		axios.defaults.headers.common["Authorization"] = "Token "+localStorage.getItem("token")
  		await axios
                .post("/api/v1/token/logout/", {})
                .then(response => {
                    
                    
			
		            localStorage.removeItem("token")
		            localStorage.removeItem("username")
		            localStorage.removeItem("userid")
		
		            this.$store.commit('removeToken')
		            this.$store.state.user = ''
		            localStorage.removeItem("user")
		            this.$router.push('/')
		            
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                    }
                })
        },
  },
  computed: {
  	toast() {
  			return this.$store.getters.toast;
  		},
      cartTotalLength() {
          let totalLength = 0
		   console.log(this.cart);
		   
          for (let i = 0; i < this.cart.items; i++) {
              totalLength += this.cart.items[i].quantity
          }

          return totalLength
      }
  }
}
</script>

<template>
	<div class="breadcrumb-area breadcrumb-bg-1 pt-50 pb-70 mb-100">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<h1 class="breadcrumb-title">{{$t('Galleries')}}</h1>
					<!--=======  breadcrumb list  =======-->
					<ul class="breadcrumb-list">
						<li class="breadcrumb-list__item"><a href="/">{{$t('Home')}}</a></li>
						<li class="breadcrumb-list__item breadcrumb-list__item--active">{{$t('Galleries')}}</li>
					</ul>
					<!--=======  End of breadcrumb list  =======-->

				</div>
			</div>
		</div>
	</div>
	<div class="product-category-container mb-100 mb-md-80 mb-sm-70" v-if="this.albums">
		<div class="container wide">
			<div class="row">
				<div class="col-lg-12">
					<div class="product-category-container--collectionhome-wrapper">
						<div class="row">
							<div class="col-lg-3 col-md-6 col-12 mb-60" v-for="album in this.albums">
								<!--=======  single category  =======-->

								<div class="single-category single-category--one wow zoomIn">
									<!--=======  single category image  =======-->

									<div class="single-category__image single-category__image--one">
										<img :src="album.thumb" class="img-fluid" :alt="album.title">
									</div>

									<!--=======  End of single category image  =======-->

									<!--=======  single category content  =======-->

									<div class="single-category__content single-category__content--one mt-25 mb-25">
										<div class="title">
											<p>{{album.title}}</p>
											<a :href="'/galleries/'+album.slug+'/'">+ View</a>
										</div>

										<p class="product-count">{{album.images.length}}</p>
									</div>
									<!--=======  End of single category content  =======-->

									<!--=======  banner-link  =======-->

									<a :href="'/galleries/'+album.slug+'/'" class="banner-link"></a>

									<!--=======  End of banner-link  =======-->
								</div>
								<!--=======  End of single category  =======-->
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
import axios from 'axios'
export default {
    name: 'Galleries',
  	data() {
	    return {
	    	albums:null,
	    	api_baseurl:"",
	    	count:0,
	      	currentPage:1,
	     	page_size:6,
	      	showNext:false,
	      	showPrev:false,
	      	showPagination:false,
	      	num_pages:0,
	    }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        document.title = this.$t('Gallery')
        this.getAlbums()
    },
    methods: {
    	loadPrev: function () {
    		this.currentPage -=1
    		this.getAlbums()
    	},
    	loadNext: function () {
    		this.currentPage +=1
    		this.getAlbums()
    	},
    	async getAlbums() {
    		if (!this.currentPage) {
				this.currentPage = 1
			} 
    		let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: true,
                onCancel: this.onCancel,
                loader:'dots',
            });
    		
	  		await axios
	                .get("/api/albums/?page="+this.currentPage+"&page_size=6")
	                .then(response => {
						//console.log(response.data.results)
						this.albums = response.data.results
						
						if (response.data.count == 0) {
	                		this.showPagination = false 
	                	} else {
	                		this.showPagination = true
	                	}
	                	this.count = response.data.count;
	                	this.num_pages = this.count/this.page_size;
	                	if(response.data.next) {
	                    	this.showNext=true
	                    } else {
	                    	this.showNext=false
	                    }
	                    if(response.data.previous) {
	                    	this.showPrev=true
	                    } else {
	                    	this.showPrev=false
	                    }
	                    
	                    setTimeout(() => {
		                    loader.hide()
		                }, 1000)
	                	
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
    },
    updated() {
	  	$(document).ready(function(){
	  		//$('.blog-masonry').masonry({percentPosition:true,itemSelector:'.column',columnWidth:'.grid-sizer',})	
	  	})
  	}
}
</script>
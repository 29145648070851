import { createRouter, createWebHistory } from 'vue-router'
import VueBodyClass from 'vue-body-class'
import store from '../store'
import DataAPI from '@/apis/data'

import Home from '../views/Home.vue'
import LayoutHome from '../layouts/LayoutHome.vue'
import LayoutDefault from '../layouts/LayoutDefault.vue'
import LayoutItem from '../layouts/LayoutItem.vue'
import LayoutPage from '../layouts/LayoutPage.vue'
import LayoutClass from '../layouts/LayoutClass.vue'
import LayoutCheckout from '../layouts/LayoutCheckout.vue'
import NotFound from '../views/404.vue'
import Contact from '../views/Contact.vue'
import Register from '../views/Register.vue'
import LogIn from '../views/LogIn.vue'
import MyAccount from '../views/MyAccount.vue'
import Search from '../views/Search.vue'
import Cart from '../views/Cart.vue'
import Success from '../views/Success.vue'
import Checkout from '../views/Checkout.vue'
import Payment from '../views/Payment.vue'
import PaymentMethod from '../views/PaymentMethod.vue'
import PaymentBankTransfer from '../views/PaymentBankTransfer.vue'
import PaymentThankYou from '../views/PaymentThankYou.vue'
import Post from '../views/Post.vue'
import RetreatPage from '../views/RetreatPage.vue'
import Page from '../views/Page.vue'
import Class from '../views/Class.vue'
import Item from '../views/Item.vue'
import TokenUri from '../views/TokenUri.vue'
import Category from '../views/Category.vue'
import ItemList from '../views/ItemList.vue'
import CategoryAll from '../views/CategoryAll.vue'
import MyProfile from '../views/MyProfile.vue'
import MyProfileEdit from '../views/MyProfileEdit.vue'
import AuthorList from '../views/AuthorList.vue'
import LiveAuction from '../views/LiveAuction.vue'
import DiscoverAsset from '../views/DiscoverAsset.vue'
import Activity from '../views/Activity.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import PostList from '../views/PostList.vue'
import ClassList from '../views/ClassList.vue'
import Gallery from '../views/Gallery.vue'
import Galleries from '../views/Galleries.vue'
import Numerology from '../views/Numerology.vue'
import PostType from '../views/PostType.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { layout: LayoutHome,bodyClass:'' },
  },
  {
    path: '/contact-us/',
    name: 'Contact Us',
    component: Contact,
    meta: { layout: LayoutDefault, bodyClass:'' },
  },
  {
    path: '/register/',
    name: 'Register',
    component: Register,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/login/',
    name: 'LogIn',
    component: LogIn,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/forgot-password/',
    name: 'Forgot Password',
    component: ForgotPassword,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/my-account/',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
        requireLogin: true,
        layout: LayoutDefault },
  },
  {
    path: '/posts/',
    name: 'PostList',
    component: PostList,
    meta: { layout: LayoutDefault, bodyClass:'cms-chinh-sach-su-dung cms-page-view page-layout-1column' },
  },
  {
    path: '/retreat-page/',
    name: 'RetreatPage',
    component: RetreatPage,
    meta: { layout: LayoutDefault },
  },
   {
    path: '/classes/',
    name: 'ClassList',
    component: ClassList,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/profile/',
    name: 'My Profile',
    component: MyProfile,
    meta: {
        requireLogin: true,
        layout: LayoutDefault },
  },
  {
    path: '/profile/edit/',
    name: 'Edit My Profile',
    component: MyProfileEdit,
    meta: {
        requireLogin: true,
        layout: LayoutDefault },
  },
  {
    path: '/authors/',
    name: 'Author List',
    component: AuthorList,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/activity/',
    name: 'Activity',
    component: Activity,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/discover-assets/',
    name: 'Discover Assets',
    component: DiscoverAsset,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/search/',
    name: 'Search',
    component: Search,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/cart/',
    name: 'Cart',
    component: Cart,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/galleries/',
    name: 'Galleries',
    component: Galleries,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/galleries/:slug/',
    name: 'Gallery',
    component: Gallery,
    meta: { layout: LayoutPage, bodyClass:'cms-chinh-sach-su-dung cms-page-view page-layout-2columns-left' },
  },
  {
    path: '/cart/success/',
    name: 'Success',
    component: Success,
    meta: { layout: LayoutDefault },
  },
   {
    path: '/numerology/',
    name: 'Numerology',
    component: Numerology,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/posts/:post_slug/',
    name: 'Post',
    component: Post,
    meta: { layout: LayoutDefault,bodyClass:'cms-chinh-sach-su-dung cms-page-view page-layout-2columns-left' },
  },
  {
    path: '/pages/:page_slug/',
    name: 'Page',
    component: Page,
    meta: { layout: LayoutPage, bodyClass:'cms-chinh-sach-su-dung cms-page-view page-layout-2columns-left' },
  },
   {
    path: '/classes/:class_slug/',
    name: 'Class',
    component: Class,
    meta: { layout: LayoutClass,bodyClass:'' },
  },
  {
    path: '/tokenuris/:tokenuri_slug/',
    name: 'TokenUri',
    component: TokenUri,
    meta: { layout: LayoutDefault },
  },
  {
    path: '/items/:slug/',
    name: 'Item',
    component: Item,
    meta: { layout: LayoutItem,bodyClass:'page-product-configurable catalog-product-view page-layout-1column' },
  },
  {
    path: '/posts/category/:category_slug/',
    name: 'Category',
    component: Category,
     meta: { layout: LayoutDefault },
  },
   {
    path: '/posts/type/:type_slug/',
    name: 'PostType',
    component: PostType,
     meta: { layout: LayoutDefault },
  },
{
    path: '/items/',
    name: 'ItemList',
    component: ItemList,
    meta: { layout: LayoutDefault,bodyClass:'page-with-filter page-products catalog-category-view page-layout-1column' },
  },
{
    path: '/items/category/all/',
    name: 'CategoryAll',
    component: CategoryAll,
    meta: { layout: LayoutDefault },
  },
  {
    path: "/:catchAll(.*)/",
    name: "NotFound",
    component: NotFound,
    meta: {
      requiresAuth: false,
      layout: LayoutDefault,
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active"
})

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) });

router.beforeEach((to, from, next) => {
  var miscData = localStorage.getItem('miscData')
    if (!miscData) {
      DataAPI.getMiscData({ no_page: true }).then(res => {
			this.$store.commit('setMiscData', res.data)
			console.log("DataAPI in main")
			console.log(res.data)
		})
    } 
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'LogIn', query: { to: to.path } });
  } else {
    next()
  }
})

export default router

<template>
	<div class="breadcrumb-area pt-20 pb-20" v-if="this.object">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<ul class="breadcrumb-list">
						<li class="breadcrumb-list__item"><a href="/">{{$t('Home')}}</a></li>
						<li class="breadcrumb-list__item"><a href="/classes/">{{$t('Class List')}}</a></li>
						<li class="breadcrumb-list__item breadcrumb-list__item--active" v-if="'vi' == $i18n.locale">{{object.name_vi}}</li>
						<li class="breadcrumb-list__item breadcrumb-list__item--active" v-else-if="'en' == $i18n.locale">{{object.name_en}}</li>
						<li class="breadcrumb-list__item breadcrumb-list__item--active" v-else>{{object.name}}</li>
					</ul>
					<!--=======  End of breadcrumb list  =======-->
				</div>
			</div>
		</div>
	</div>
	<div class="shop-page-wrapper mt-30 mb-100" v-if="object">
		<div class="container">
			<div class="row">
				<div id="object-content">
					<!--=======  shop product content  =======-->
					<div class="shop-product">
						<div class="row pb-10">
							<div class="col-lg-8 col-md-8">
								<!--=======  shop product big image gallery  =======-->
								<!--=======  End of shop product big image gallery  =======-->

								<!--=======  shop product small image gallery  =======-->
								<div v-if="paid">
									<video-player class="is-16by9" :src="this.stream_link" 
										controls
										:loop="true"
	    								:volume="0.6"
	    								:width="750"
									/>
								</div>
								<div v-else>
									<img :alt="object.name" :src="object.featured_image_url"/>	
								</div>
								<!--=======  End of shop product small image gallery  =======-->
							</div>

							<div class="col-lg-4 col-md-4">
								<!--=======  shop product description  =======-->

								<div class="shop-product__description">
									<!--=======  shop product navigation  =======-->

									<!--=======  End of shop product navigation  =======-->

									<!--=======  shop product rating  =======-->

									<div class="shop-product__rating mb-15">
										<span class="product-rating">
											<i class="active ion-android-star"></i>
											<i class="active ion-android-star"></i>
											<i class="active ion-android-star"></i>
											<i class="active ion-android-star"></i>
											<i class="ion-android-star-outline"></i>
										</span>
									</div>
									<!--=======  End of shop product rating  =======-->
									<!--=======  shop product title  =======-->
									<div class="shop-product__title">
										<h2 v-if="'vi' == $i18n.locale">{{object.name_vi}}</h2>
										<h2 v-else-if="'en' == $i18n.locale">{{object.name_en}}</h2>
										<h2 v-else>{{object.name}}</h2>
									</div>

									<!--=======  End of shop product title  =======-->
									<!--=======  shop product short description  =======-->
									<div class="shop-product__short-desc mb-50" v-if="'vi' == $i18n.locale">
										{{object.description_vi}}
									</div>
									<div class="shop-product__short-desc mb-50" v-else-if="'en' == $i18n.locale">
										{{object.description_en}}
									</div>
									<div class="shop-product__short-desc mb-50" v-else>
										{{object.description}}
									</div>
									<!--=======  End of shop product short description  =======-->
									<!--=======  shop product price  =======-->
									<select class="shop-product__price mb-20 form-group" v-if="object.metadata">
											<option v-for="metad in object.metadata">
											<span class="discounted-price" v-if="metad.price > 0">{{metad.currency.symbol}} {{metad.price}} {{metad.unit.name}}</span>
											</option>
									</select>
									<!--=======  End of shop product price  =======-->
								</div>
								<div v-if="paid">
									<h4 v-if="object.links">{{$t('Video list')}}</h4>
									<ul v-if="object.links">
										<li v-for="(link, index) in object.links">
											<a v-if="link.stream_link" data-src="link.stream_link" v-on:click="playVideo(link.stream_link)">{{index+1}}. {{link.title}}</a>
										</li>
									</ul>
								</div>
								<div class="order" v-else>
									<date-picker class="form-group" :dateInput="dateInput" :availableDates="availableDates4" />
									<div class="form-group">
										<input type="text" name="fullname" id="fullname" class="form-control" ref="fullname" :placeholder="$t('Fullname')">
									</div>
									<div class="form-group">
										<input type="text" name="email" id="email" class="form-control" ref="email" :placeholder="$t('Email')">
									</div>
									<div class="form-group">
										<input type="text" name="phone" id="phone" class="form-control" ref="phone" :placeholder="$t('Phone')">
									</div>
									<div class="form-group">
										<input type="text" name="address" id="address" class="form-control" ref="address" :placeholder="$t('Address')">
									</div>
									<div class="form-group">
										<textarea name="note" class="form-control" id="note" ref="note" cols="30" rows="5" :placeholder="$t('Note')"></textarea>
									</div>
									<input type="hidden" name="content_type" id="content_type" class="form-control" ref="content_type" :value="object.content_type_id">
									<input type="hidden" name="object_id" id="object_id" class="form-control" ref="object_id" :value="object.id">
									<input type="hidden" name="price" id="price" class="form-control" ref="price" value="0">
									<div class="form-group">
											<button type="submit" :title="$t('Place Order')" class="lezada-button lezada-button--medium mt-10 mb-10 primary" id="product-addtocart-button" v-on:click="objectRegistration">
								                {{$t('Place Order')}}
								            </button>
								            <p>{{$t('Pick up at Store or in your door')}}</p>
									</div>
								</div>
								<!--=======  End of shop product description  =======-->
							</div>
						</div>

						<div class="row">
							<div class="col-lg-12">
								<!--=======  shop product description tab  =======-->
								<div class="shop-product__description-tab pt-10">
									<div class="" v-if="'vi' == $i18n.locale" v-html="object.content_vi"></div>			
									<div class="" v-else-if="'en' == $i18n.locale" v-html="object.content_en"></div>	
									<div class="" v-else v-html="object.content"></div>															
								</div>
								<!--=======  End of shop product description tab  =======-->
							</div>
						</div>
					</div>

					<!--=======  End of shop product content  =======-->
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import config from '../config.js';
import { defineComponent, registerRuntimeCompiler, toRefs } from "vue";
import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import StarRating from 'vue-star-rating';
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";


export default {
    name: 'Class',
    components: {
    	Carousel,
	    Slide,
	    Pagination,
	    Navigation,
	    StarRating,
    	VideoPlayer,
    	DatePicker
    },
    data() {
        return {
        	dateInput: {
                placeholder: "Select Date",
              },
              availableDates1: {
                from: new Date("2021 06 30"),
                to: new Date("2021 09 01"),
              },
              availableDates2: {
                ranges: [
                  {
                    from: new Date("2021 05 01"),
                    to: new Date("2021 05 31"),
                  },
                  {
                    from: new Date("2021 09 01"),
                    to: new Date("2021 09 31"),
                  },
                ],
              },
              availableDates3: {
                dates: [
                  new Date("2021 09 01"),
                  new Date("2021 07 26"),
                  new Date("2021 09 05"),
                ],
              },
              availableDates4: {
                custom(date) {
                  return date.getDay() === 1;
                },
              },
        	paid: false,
        	isLoading: false,
            fullPage: false,
            stream_link: axios.defaults.baseURL+ "/api/stream/?fid=17#.mp4",
            object: null,
            object_registration_id:0,
            videoOptions: {
                autoplay: true,
                controls: true,
                preload: 'auto',
                // aspectRatio:"16:9", 
                fluid: true,
                playbackRates: [0.2, 0.5, 1, 1.5, 2,3,4],
                sources: [
                    { 
                        src: axios.defaults.baseURL+ "/api/stream/?fid=17#.mp4",
                        type: "video/mp4"
                    },
                    { 
                        src: axios.defaults.baseURL+ "/api/stream/?fid=17#.mp4",
                        type: "video/mp4"
                    },
                ],
            }
        }
    },
    mounted() {
        this.getClass()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Class') {
                this.getClass()
            }
        }
    },
    methods: {
    	playVideo(stream_link) {
    		this.stream_link = stream_link				
    	},
    	async objectRegistration() {
    		
    		const user = JSON.parse(localStorage.getItem("user"));
    		const formData = {
	 			fullname: this.$refs.fullname.value||"undefined",
				email: this.$refs.email.value||"undefined",
				phone: this.$refs.phone.value||"undefined",
				address: this.$refs.address.value||"undefined",
				note: this.$refs.note.value||"undefined",
				content_type: this.$refs.content_type.value||29,
				object_id: this.$refs.object_id.value||0,
				status:'created',
				user:user.id,
	    	}
    		//this.$router.push({ name: 'Payment',params: {object_registration_id:1,form_data:this.formData}})
    		//return 1
	    //console.log(formData)
	    await axios
                .post("/api/objectregistrations/", formData)
                .then(response => {
                	console.log(response.data);
                	this.object_registration_id = response.data.id;
                	this.$router.push('/payment/?object_registration_id='+this.object_registration_id);
                	//this.$router.push({ name: 'Payment',params: {object_registration_id:response.data.id,form_data:this.formData}})
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    	},
    	doAjax() {
        	this.isLoading = true;
                // simulate AJAX
                setTimeout(() => {
                    this.isLoading = false
                }, 5000)
        },
        onCancel() {
        	console.log('User cancelled the loader.')
        },
        async getClass() {
            const classSlug = this.$route.params.class_slug
			
			let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    onCancel: this.onCancel,
                    loader:'dots',
                });
            axios
                .get(`/api/classes/${classSlug}/`)
                .then(response => {
                    this.object = response.data
                    if ('vi' == this.$i18n.locale) {
                    	document.title = this.object.name_vi
                    } else if  ('en' == this.$i18n.locale) {
                    	document.title = this.object.name_en
                    } else {
                    	document.title = this.object.name
                    }
                    
                    setTimeout(() => {
	                    loader.hide()
	                }, 1000)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>
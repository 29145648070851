<template>
	<section class="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65" v-if="this.object">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<h1>{{this.object.title}}</h1>
					<ol class="breadcrumb">
						<li class="breadcrumb-item"><a href="/">Trang Chủ</a></li>
						<li class="breadcrumb-item"><a href="#">{{object.type.title}}</a></li>
						<li class="breadcrumb-item active">{{this.object.title}}</li>
					</ol>
				</div>
			</div>
		</div>
	</section>
	<section class="ds s-py-90 s-py-xl-150" v-if="this.object">
				<div class="container">
					<div class="row c-gutter-60">
						<main class="col-lg-12 col-xl-12">
							<article class="vertical-item content-padding padding-big ds bs bordered post type-post status-publish format-standard has-post-thumbnail">
								<!-- .post-thumbnail -->
								<div class="item-media post-thumbnail">
									<img :src="object.featured_image" :alt="object.title">
								</div>
								<div class="item-content">
									<header class="entry-header single-post">
										<span class="cat-links">
											<span class="screen-reader-text">Categories</span>
										</span>
										<div class="entry-meta">
											<span>
												<a :href="'/posts/'+object.slug+'/'" rel="bookmark">
													<time class="entry-date published updated" :datetime="object.created_at">{{getFormattedDate(object.created_at)}}</time>
												</a>
											</span>
										</div>
										<!-- .entry-meta -->

									</header>
									<!-- .entry-header -->
									<div class="divider-20 divider-xl-40"></div>
									<div v-html="object.content" class="entry-content">
										
									</div><!-- .entry-content -->
								</div>
								<!-- .item-content -->
							</article>
						</main>
					</div>
				</div>
	</section>
</template>

<script>
import moment from 'moment';
import axios from 'axios'
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";
export default {
    name: 'Post',
    components: {
    	DatePicker
    },
    data() {
        return {
        	dateInput: {
                placeholder: "Select Date",
            },
            availableDates1: {
                from: new Date("2021 06 30"),
                to: new Date("2021 09 01"),
            },
            availableDates2: {
                ranges: [
                  {
                    from: new Date("2021 05 01"),
                    to: new Date("2021 05 31"),
                  },
                  {
                    from: new Date("2021 09 01"),
                    to: new Date("2021 09 31"),
                  },
                ],
            },
            availableDates3: {
                dates: [
                  new Date("2021 09 01"),
                  new Date("2021 07 26"),
                  new Date("2021 09 05"),
                ],
            },
            availableDates4: {
                custom(date) {
                  return date.getDay() === 1;
                },
            },
            object: {},
            api_baseurl:"",
        	paid: false,
        	isLoading: false,
            fullPage: false,
            stream_link: axios.defaults.baseURL+ "/api/stream/?fid=17#.mp4",
            object: null,
            object_registration_id:0,
            videoOptions: {
                autoplay: true,
                controls: true,
                preload: 'auto',
                // aspectRatio:"16:9", 
                fluid: true,
                playbackRates: [0.2, 0.5, 1, 1.5, 2,3,4],
                sources: [
                    { 
                        src: axios.defaults.baseURL+ "/api/stream/?fid=17#.mp4",
                        type: "video/mp4"
                    },
                    { 
                        src: axios.defaults.baseURL+ "/api/stream/?fid=17#.mp4",
                        type: "video/mp4"
                    },
                ],
            }
        }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        this.getPost()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Post') {
                this.getPost()
            }
        }
    },
    methods: {
    	 getFormattedDate(date) {
             return moment(date).format("YYYY-MM-DD")
         },
		async objectRegistration() {
    		const user = JSON.parse(localStorage.getItem("user"));
    		const formData = {
	 			fullname: this.$refs.fullname.value||"undefined",
				email: this.$refs.email.value||"undefined",
				phone: this.$refs.phone.value||"undefined",
				address: this.$refs.address.value||"undefined",
				note: this.$refs.note.value||"undefined",
				content_type: this.$refs.content_type.value||29,
				object_id: this.$refs.object_id.value||0,
				status:'created',
				user:user.id,
	    	}
    		//this.$router.push({ name: 'Payment',params: {object_registration_id:1,form_data:this.formData}})
    		//return 1
	    console.log(formData)
	    await axios
                .post("/api/objectregistrations/", formData)
                .then(response => {
                	console.log(response.data);
                	this.object_registration_id = response.data.id;
                	this.$router.push('/payment/?object_registration_id='+this.object_registration_id);
                	//this.$router.push({ name: 'Payment',params: {object_registration_id:response.data.id,form_data:this.formData}})
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    	},
    	async bookAService() {
    		const user = JSON.parse(localStorage.getItem("user"));
    		const serviceFormData = {
	 			fullname: this.$refs.fullname.value||"undefined",
				email: this.$refs.email.value||"undefined",
				phone: this.$refs.phone.value||"undefined",
				address: this.$refs.address.value||"undefined",
				note: this.$refs.note.value||"undefined",
				content_type: this.$refs.content_type.value||29,
				object_id: this.$refs.object_id.value||0,
				status:'created',
				user:user.id,
	    	}
	    console.log(serviceFormData)
	    await axios
                .post("/api/objectregistrations/", serviceFormData)
                .then(response => {
                	console.log(response.data);
					
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    	},
        async getPost() {
            const postSlug = this.$route.params.post_slug

			let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    onCancel: this.onCancel,
            });
            
            axios
                .get(`/api/posts/${postSlug}/`)
                .then(response => {
                    this.object = response.data
                    console.log(response.data)
                    document.title = this.object.title 
                    setTimeout(() => {
		            	loader.hide()
		            }, 1000)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>
<template>
	<section class="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<h1>{{page.title}}</h1>
							<ol class="breadcrumb">
								<li class="breadcrumb-item">
									<a href="/">Trang Chủ</a>
								</li>
								<li class="breadcrumb-item">
									<a href="#">Pages</a>
								</li>
								<li class="breadcrumb-item active">
									{{page.title}}
								</li>
							</ol>
						</div>

					</div>
				</div>
	</section>
	<section class="ds s-py-90 s-py-xl-150 c-gutter-60">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<div class="vertical-item bg-darkblue text-center content-padding padding-big">
								<div v-html="page.content" class="item-content max-content">
								</div>
							</div>
							<div class="share_buttons social_part">
								<a href="https://www.facebook.com" class="color-bg-icon fa fa-facebook" target="_blank"></a>
								<a href="https://twitter.com" class="color-bg-icon fa fa-twitter" target="_blank"></a>
								<a href="https://pinterest.com" class="color-bg-icon fa fa-pinterest" target="_blank"></a>
								<a href="https://instagram.com" class="color-bg-icon fa fa-instagram" target="_blank"></a>
								<a href="https://www.youtube.com" class="color-bg-icon fa fa-youtube-play" target="_blank"></a>
							</div>
						</div><!-- .col-* -->
					</div>
				</div>
	</section>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Page',
    components: {
    },
    data() {
        return {
        	fullPage: false,
            page: {},
            miscData:{},
        }
    },
    mounted() {
    	document.title = 'Page loading';
    	this.miscData = JSON.parse(localStorage.getItem("miscData"));
        this.getPage();
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Page') {
                this.getPage()
            }
        }
    },
    methods: {
            onCancel() {
                console.log('User cancelled the loader.')
            },
        async getPage() {
            const pageSlug = this.$route.params.page_slug
			let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    onCancel: this.onCancel,
                    loader:'dots',
                });

            axios
                .get(`/api/pages/${pageSlug}/`)
                .then(response => {
                    this.page = response.data
                    document.title = this.page.title
                    setTimeout(() => {
	                    loader.hide()
	                }, 1000)
                })
                .catch(error => {
                    console.log(error)
                })

        }
    }
}
</script>
<template>
	<div class="breadcrumb-area breadcrumb-bg-1 pt-50 pb-70 mb-100" v-if="this.album">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<h1 class="breadcrumb-title">{{$('Gallery')}}</h1>
					<!--=======  breadcrumb list  =======-->
					<ul class="breadcrumb-list">
						<li class="breadcrumb-list__item"><a href="/">{{$('Home')}}</a></li>
						<li class="breadcrumb-list__item"><a href="/galleries/">{{$('Galleries')}}</a></li>
						<li class="breadcrumb-list__item breadcrumb-list__item--active">{{album.title}}</li>
					</ul>
					<!--=======  End of breadcrumb list  =======-->

				</div>
			</div>
		</div>
	</div>
	<div class="product-category-container mb-100 mb-md-80 mb-sm-70" v-if="this.album">
		<div class="container wide">
			<div class="row">
				<div class="col-lg-12">
					<div class="product-category-container--collectionhome-wrapper">
						<div class="row">
							<div class="col-lg-3 col-md-6 col-12 mb-60" v-for="image in this.album.images">
								<!--=======  single category  =======-->

								<div class="single-category single-category--one wow zoomIn">
									<!--=======  single category image  =======-->

									<div class="single-category__image single-category__image--one">
										<img :src="image.image_url" class="img-fluid" :alt="album.title">
									</div>

									<!--=======  End of single category image  =======-->

									<!--=======  single category content  =======-->

									<div class="single-category__content single-category__content--one mt-25 mb-25">
										<div class="title">
											<p>{{album.title}}</p>
										</div>
									</div>


								</div>

								<!--=======  End of single category  =======-->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
import axios from 'axios'
export default {
    name: 'Gallery',
  	data() {
	    return {
	    	album:null,
	    	api_baseurl:"",
	    }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        document.title = this.$t('Gallery')
        this.getAlbum()
    },
    methods: {
    	async getAlbum() {
			const slug = this.$route.params.slug
			
			let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: true,
                onCancel: this.onCancel,
                loader:'dots',
            });
			
	  		await axios
	                .get("/api/albums/"+slug+"/")
	                .then(response => {
						//console.log(response.data)
						this.album = response.data
						setTimeout(() => {
		                    loader.hide()
		                }, 1000)
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
    },
    updated() {
	  	$(document).ready(function(){
	  		//$('.blog-masonry').masonry({percentPosition:true,itemSelector:'.column',columnWidth:'.grid-sizer',})	
	  	})
  	}
}
</script>
<template>
	<div class="breadcrumb-area breadcrumb-bg-2 pt-50 pb-70">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<h1 class="breadcrumb-title">Shop</h1>
					<!--=======  breadcrumb list  =======-->
					<ul class="breadcrumb-list">
						<li class="breadcrumb-list__item"><a href="/">HOME</a></li>
						<li class="breadcrumb-list__item breadcrumb-list__item--active">Item List</li>
					</ul>
					<!--=======  End of breadcrumb list  =======-->

				</div>
			</div>
		</div>
	</div>
	<div class="shop-page-wrapper">

		<!--=======  shop page header  =======-->

		<div class="shop-page-header">
			<div class="container">
				<div class="row align-items-center">

					<div class="col-12 col-lg-7 col-md-10 d-none d-md-block">
						<!--=======  fitler titles  =======-->
						<div class="filter-title filter-title--type-two">
							<ul class="product-filter-menu">
								<li class="active" data-filter="*">All</li>
								<li data-filter=".hot">hot products</li>
								<li data-filter=".new">new products</li>
								<li data-filter=".sale">sale products</li>
							</ul>
						</div>
						<!--=======  End of fitler titles  =======-->
					</div>

					<div class="col-12 col-lg-5 col-md-2">
						<!--=======  filter icons  =======-->

						<div class="filter-icons">
							<!--=======  filter dropdown  =======-->

							<div class="single-icon filter-dropdown">
								<select class="nice-select" style="display: none;">
									<option value="0">Default sorting</option>
									<option value="1">Sort ny popularity</option>
									<option value="0">Sort by average rating</option>
									<option value="0">Sort by latest</option>
									<option value="0">Sort by price: low to high</option>
									<option value="0">Sort by price: high to low</option>
								</select><div class="nice-select" tabindex="0"><span class="current">Default sorting</span><ul class="list"><li data-value="0" class="option selected">Default sorting</li><li data-value="1" class="option">Sort ny popularity</li><li data-value="0" class="option">Sort by average rating</li><li data-value="0" class="option">Sort by latest</li><li data-value="0" class="option">Sort by price: low to high</li><li data-value="0" class="option">Sort by price: high to low</li></ul></div>
							</div>

							<!--=======  End of filter dropdown  =======-->

							<!--=======  grid icons  =======-->

							<div class="single-icon grid-icons">
								<a data-target="five-column" href="javascript:void(0)"><i class="ti-layout-grid4-alt"></i></a>
								<a data-target="four-column" class="active" href="javascript:void(0)"><i class="ti-layout-grid3-alt"></i></a>
								<a data-target="three-column" href="javascript:void(0)"><i class="ti-layout-grid2-alt"></i></a>
								<a data-target="list" href="javascript:void(0)"><i class="ti-view-list"></i></a>
							</div>

							<!--=======  End of grid icons  =======-->

							<!--=======  advance filter icon  =======-->

							<div class="single-icon advance-filter-icon">
								<a href="javascript:void(0)" id="advance-filter-active-btn"><i class="ion-android-funnel"></i>
									Filters</a>
							</div>

							<!--=======  End of advance filter icon  =======-->
						</div>

						<!--=======  End of filter icons  =======-->
					</div>

				</div>
			</div>
		</div>

		<!--=======  End of shop page header  =======-->

		<!--=============================================
        =            shop advance filter area         =
        =============================================-->

		<div class="shop-advance-filter-area" id="shop-advance-filter-area">
			<div class="shop-advance-filter-wrapper pt-50">
				<div class="container">
					<div class="row">
						<div class="col-lg-2 col-md-4 col-sm-6 mb-50">
							<!--=======  single advance filte  =======-->

							<div class="single-filter-widget">
								<h2 class="single-filter-widget--title">Sort by</h2>
								<ul class="single-filter-widget--list">
									<li><a href="#">Default</a></li>
									<li><a href="#">Popularity</a></li>
									<li><a href="#">Average rating</a></li>
									<li><a href="#">Newness</a></li>
									<li><a href="#">Price: low to high</a></li>
									<li><a href="#">Price: high to low</a></li>
								</ul>
							</div>

							<!--=======  End of single advance filte  =======-->
						</div>

						<div class="col-lg-2 col-md-4 col-sm-6 mb-50">
							<!--=======  single advance filte  =======-->

							<div class="single-filter-widget">
								<h2 class="single-filter-widget--title">Categories</h2>
								<ul class="single-filter-widget--list single-filter-widget--list--category">
									<li class="has-children">
										<a href="shop-left-sidebar.html">Cosmetic </a> <span class="quantity">5</span>
										<ul>
											<li><a href="shop-left-sidebar.html">For body</a></li>
											<li><a href="shop-left-sidebar.html">Make up</a></li>
											<li><a href="shop-left-sidebar.html">New</a></li>
											<li><a href="shop-left-sidebar.html">Perfumes</a></li>
										</ul>
									<a href="#" class="expand-icon">+</a></li>
									<li class="has-children">
										<a href="shop-left-sidebar.html">Furniture </a> <span class="quantity">23</span>
										<ul>
											<li><a href="shop-left-sidebar.html">Sofas</a></li>
											<li><a href="shop-left-sidebar.html">Armchairs</a></li>
											<li><a href="shop-left-sidebar.html">Desk Chairs</a></li>
											<li><a href="shop-left-sidebar.html">Dining Chairs</a></li>
										</ul>
									<a href="#" class="expand-icon">+</a></li>
									<li><a href="shop-left-sidebar.html">Watches </a> <span class="quantity">12</span></li>
								</ul>
							</div>

							<!--=======  End of single advance filte  =======-->
						</div>

						<div class="col-lg-2 col-md-4 col-sm-6 mb-50">
							<!--=======  single advance filte  =======-->

							<div class="single-filter-widget">
								<h2 class="single-filter-widget--title">Price filter</h2>
								<ul class="single-filter-widget--list">
									<li><a href="#">All</a></li>
									<li><a href="#">$0.00 - $70.00</a></li>
									<li><a href="#">$70.00 - $140.00</a></li>
									<li><a href="#">$140.00 - $210.00</a></li>
									<li><a href="#">$210.00 - $280.00</a></li>
									<li><a href="#">$280.00 - $350.00</a></li>
								</ul>
							</div>

							<!--=======  End of single advance filte  =======-->
						</div>

						<div class="col-lg-2 col-md-4 col-sm-6 mb-50">
							<!--=======  single advance filte  =======-->

							<div class="single-filter-widget">
								<h2 class="single-filter-widget--title">Color</h2>
								<ul class="single-filter-widget--list single-filter-widget--list--color">
									<li><a class="active" href="#" data-tippy="Black" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder"><span class="color-picker black"></span></a></li>
									<li><a href="#" data-tippy="Blue" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder"><span class="color-picker blue"></span></a></li>
									<li><a href="#" data-tippy="Brown" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder"><span class="color-picker brown"></span></a></li>
									<li><a href="#" data-tippy="Gold" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder"><span class="color-picker gold"></span></a></li>
									<li><a href="#" data-tippy="Green Coral" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder"><span class="color-picker green-coral"></span></a></li>
									<li><a href="#" data-tippy="Grey" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder"><span class="color-picker grey"></span></a></li>
									<li><a href="#" data-tippy="Oak" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder"><span class="color-picker oak"></span></a></li>
									<li><a href="#" data-tippy="Pink" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder"><span class="color-picker pink"></span></a></li>
									<li><a href="#" data-tippy="Silver" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder"><span class="color-picker silver"></span></a></li>
									<li><a href="#" data-tippy="White" data-tippy-inertia="true" data-tippy-animation="shift-away" data-tippy-delay="50" data-tippy-arrow="true" data-tippy-theme="sharpborder"><span class="color-picker white"></span></a></li>
								</ul>
							</div>

							<!--=======  End of single advance filte  =======-->
						</div>

						<div class="col-lg-2 col-md-4 col-sm-6 mb-50">
							<!--=======  single advance filte  =======-->

							<div class="single-filter-widget">
								<h2 class="single-filter-widget--title">Size</h2>
								<ul class="single-filter-widget--list single-filter-widget--list--size">
									<li><a href="#">L</a> <span class="quantity">5</span></li>
									<li><a href="#">M</a> <span class="quantity">5</span></li>
									<li><a href="#">S</a> <span class="quantity">5</span></li>
									<li><a href="#">XS</a> <span class="quantity">5</span></li>
								</ul>
							</div>

							<!--=======  End of single advance filte  =======-->
						</div>

						<div class="col-lg-2 col-md-4 col-sm-6 mb-50">
							<!--=======  single advance filte  =======-->

							<div class="single-filter-widget">
								<h2 class="single-filter-widget--title">Brands</h2>
								<ul class="single-filter-widget--list single-filter-widget--list--brand">
									<li><a href="#">Alliop</a> <span class="quantity">(12)</span></li>
									<li><a href="#">Burberry</a> <span class="quantity">(15)</span></li>
									<li><a href="#">Catmen</a> <span class="quantity">(13)</span></li>
									<li><a href="#">Houdini</a> <span class="quantity">(10)</span></li>
									<li><a href="#">Love</a> <span class="quantity">(70)</span></li>
									<li><a href="#">Made</a> <span class="quantity">(15)</span></li>
								</ul>
							</div>

							<!--=======  End of single advance filte  =======-->
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--=====  End of shop advance filter area  ======-->

		<!--=============================================
		=            shop page content         =
		=============================================-->

		<div class="shop-page-content mt-100 mb-100">
			<div class="container">
				<div class="row">
					<div class="col-lg-3 order-2 order-lg-1">
						<!--=======  page sidebar  =======-->

						<div class="page-sidebar">
							<!--=======  single sidebar widget  =======-->

							<div class="single-sidebar-widget mb-40">
								<!--=======  search widget  =======-->

								<div class="search-widget">
									<form action="#">
										<input type="search" placeholder="Search products ...">
										<button type="button"><i class="ion-android-search"></i></button>
									</form>
								</div>

								<!--=======  End of search widget  =======-->
							</div>


							<!--=======  End of single sidebarwidget  =======-->

							<!--=======  single sidebar widget  =======-->

							<div class="single-sidebar-widget mb-40">
								<h2 class="single-sidebar-widget--title">Categories</h2>
								<ul class="single-sidebar-widget--list single-sidebar-widget--list--category">
									<li class="has-children">
										<a href="shop-left-sidebar.html">Cosmetic </a> <span class="quantity">5</span>
										<ul>
											<li><a href="shop-left-sidebar.html">For body</a></li>
											<li><a href="shop-left-sidebar.html">Make up</a></li>
											<li><a href="shop-left-sidebar.html">New</a></li>
											<li><a href="shop-left-sidebar.html">Perfumes</a></li>
										</ul>
									<a href="#" class="expand-icon">+</a></li>
									<li class="has-children">
										<a href="shop-left-sidebar.html">Furniture </a> <span class="quantity">23</span>
										<ul>
											<li><a href="shop-left-sidebar.html">Sofas</a></li>
											<li><a href="shop-left-sidebar.html">Armchairs</a></li>
											<li><a href="shop-left-sidebar.html">Desk Chairs</a></li>
											<li><a href="shop-left-sidebar.html">Dining Chairs</a></li>
										</ul>
									<a href="#" class="expand-icon">+</a></li>
									<li><a href="shop-left-sidebar.html">Watches </a> <span class="quantity">12</span></li>
									<li><a href="shop-left-sidebar.html">Bags </a> <span class="quantity">22</span></li>
									<li><a href="shop-left-sidebar.html">Uncategorized </a> <span class="quantity">14</span></li>
								</ul>
							</div>

							<!--=======  End of single sidebar widget  =======-->

							<!--=======  single sidebar widget  =======-->

							<div class="single-sidebar-widget mb-40">
								<h2 class="single-sidebar-widget--title">Filters</h2>
								<div class="sidebar-price">
									<div id="price-range" class="ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all"><div class="ui-slider-range ui-widget-header ui-corner-all" style="left: 0%; width: 100%;"></div><span class="ui-slider-handle ui-state-default ui-corner-all" tabindex="0" style="left: 0%;"></span><span class="ui-slider-handle ui-state-default ui-corner-all" tabindex="0" style="left: 100%;"></span></div>
									<div class="output-wrapper mt-20">
										<input type="text" id="price-amount" class="price-amount" readonly="">
										<a class="price-range-button" href="#"><i class="ion-android-funnel"></i> Filter</a>
									</div>
								</div>
							</div>

							<!--=======  End of single sidebar widget  =======-->

							<!--=======  single sidebar widget  =======-->

							<div class="single-sidebar-widget mb-40">
								<h2 class="single-sidebar-widget--title">Popular products</h2>

								<!--=======  widget product wrapper  =======-->

								<div class="widget-product-wrapper">
									<!--=======  single widget product  =======-->
									<div class="single-widget-product-wrapper">
										<div class="single-widget-product">
											<!--=======  image  =======-->

											<div class="single-widget-product__image">
												<a href="shop-product-basic.html">
													<img src="" class="img-fluid" alt="">
												</a>
											</div>

											<!--=======  End of image  =======-->

											<!--=======  content  =======-->

											<div class="single-widget-product__content">

												<div class="single-widget-product__content__top">
													<h3 class="product-title"><a href="shop-product-basic.html">Wooden Table</a></h3>
													<div class="price">
														<span class="main-price discounted">$270.00</span>
														<span class="discounted-price">$220.00</span>
													</div>
													<div class="rating">
														<i class="ion-android-star"></i>
														<i class="ion-android-star"></i>
														<i class="ion-android-star-outline"></i>
														<i class="ion-android-star-outline"></i>
														<i class="ion-android-star-outline"></i>
													</div>
												</div>

											</div>

											<!--=======  End of content  =======-->
										</div>
									</div>

									<!--=======  End of single widget product  =======-->

									<!--=======  single widget product  =======-->
									<div class="single-widget-product-wrapper">
										<div class="single-widget-product">
											<!--=======  image  =======-->

											<div class="single-widget-product__image">
												<a href="shop-product-basic.html">
													<img src="" class="img-fluid" alt="">
												</a>
											</div>

											<!--=======  End of image  =======-->

											<!--=======  content  =======-->

											<div class="single-widget-product__content">

												<div class="single-widget-product__content__top">
													<h3 class="product-title"><a href="shop-product-basic.html">Sofa with cushion</a></h3>
													<div class="price">
														<span class="main-price discounted">$660.00</span>
														<span class="discounted-price">$600.00</span>
													</div>
													<div class="rating">
														<i class="ion-android-star"></i>
														<i class="ion-android-star"></i>
														<i class="ion-android-star"></i>
														<i class="ion-android-star"></i>
														<i class="ion-android-star"></i>
													</div>
												</div>



											</div>

											<!--=======  End of content  =======-->
										</div>
									</div>

									<!--=======  End of single widget product  =======-->

									<!--=======  single widget product  =======-->
									<div class="single-widget-product-wrapper">
										<div class="single-widget-product">
											<!--=======  image  =======-->

											<div class="single-widget-product__image">
												<a href="shop-product-basic.html">
													<img src="" class="img-fluid" alt="">
												</a>
											</div>

											<!--=======  End of image  =======-->

											<!--=======  content  =======-->

											<div class="single-widget-product__content">

												<div class="single-widget-product__content__top">
													<h3 class="product-title"><a href="shop-product-basic.html">High waist trousers</a></h3>
													<div class="price">
														<span class="main-price discounted">$36.00</span>
														<span class="discounted-price">$33.00</span>
													</div>
													<div class="rating">
														<i class="ion-android-star"></i>
														<i class="ion-android-star"></i>
														<i class="ion-android-star"></i>
														<i class="ion-android-star"></i>
														<i class="ion-android-star-outline"></i>
													</div>
												</div>


											</div>

											<!--=======  End of content  =======-->
										</div>
									</div>
									<!--=======  End of single widget product  =======-->
								</div>

								<!--=======  End of widget product wrapper  =======-->
							</div>

							<!--=======  End of single sidebar widget  =======-->

							<!--=======  single sidebar widget  =======-->

							<div class="single-sidebar-widget">
								<h2 class="single-sidebar-widget--title">Tags</h2>

								<div class="tag-container">
									<a href="#">bags</a>
									<a href="#">chair</a>
									<a href="#">clock</a>
									<a href="#">comestic</a>
									<a href="#">fashion</a>
									<a href="#">furniture</a>
									<a href="#">holder</a>
									<a href="#">mask</a>
									<a href="#">men</a>
									<a href="#">oil</a>
								</div>
							</div>

							<!--=======  End of single sidebar widget  =======-->
						</div>

						<!--=======  End of page sidebar  =======-->
					</div>
					<div class="col-lg-9 order-1 order-lg-2 mb-md-80 mb-sm-80">

						<div class="row product-isotope shop-product-wrap four-column">

							<!--=======  single product  =======-->
							<div class="col-12 col-lg-3 col-md-6 col-sm-6 mb-45 hot sale" v-for="object in items">
								<div class="single-product">
									<!--=======  single product image  =======-->
									<div class="single-product__image">
										<a class="image-wrap" :href="'/items/'+object.slug+'/'">
											<img :src="object.featured_image_url" class="img-fluid" :alt="object.title">
											<img :src="object.hovered_image_url" class="img-fluid" :alt="object.title">
										</a>

										<div class="single-product__floating-badges">
											<span class="onsale">-10%</span>
											<span class="hot">hot</span>
										</div>
										
									</div>

									<!--=======  End of single product image  =======-->

									<!--=======  single product content  =======-->

									<div class="single-product__content">
										<div class="title">
											<h3><a :href="'/items/'+object.slug+'/'">{{object.title}}</a></h3>
										</div>
										<div class="price" v-if="object.metadata">
											<ul v-for="metad in object.metadata">
												<li class="discounted-price">{{metad.currency.symbol}} {{metad.price}} {{metad.unit.name}}</li>
											</ul>
										</div>
									</div>

									<!--=======  End of single product content  =======-->
								</div>
							</div>
							<!--=======  End of single product  =======-->

						</div>

						<div class="row">
							<div class="col-lg-12 text-center mt-30">
								<a class="lezada-button lezada-button--medium lezada-button--icon--left" href="#"><i class="ion-android-add"></i> MORE</a>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

		<!--=====  End of shop page content  ======-->
	</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ItemList',
    components: {
    },
    data() {
        return {
            items:null,
            count:0,
	      	currentPage:1,
	     	page_size:6,
	      	showNext:false,
	      	showPrev:false,
	      	showPagination:false,
	      	num_pages:0,
	      	fullPage:false,
        }
    },
    mounted() {
        this.getItemList()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'ItemList') {
                this.getItemList()
            }
        }
    },
    methods: {
    	loadPrev: function () {
    		this.currentPage -=1
    		this.getItemList()
    	},
    	loadNext: function () {
    		this.currentPage +=1
    		this.getItemList()
    	},
        async getItemList() {
        	if (!this.currentPage) {
				this.currentPage = 1
			} 
			let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    onCancel: this.onCancel,
            });
                
            const category = this.$route.query.category
			const collection = this.$route.query.collection
			const type = this.$route.query.type
			const tag = this.$route.query.tag
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/items/?'+"page="+this.currentPage+"&page_size=15")
                .then(response => {
                	console.log(response.data.results)
					this.items	= response.data.results				
                    if (response.data.count == 0) {
	                		this.showPagination = false 
	                	} else {
	                		this.showPagination = true
	                	}
	                	this.count = response.data.count;
	                	this.num_pages = this.count/this.page_size;
	                	if(response.data.next) {
	                    	this.showNext=true
	                    } else {
	                    	this.showNext=false
	                    }
	                    if(response.data.previous) {
	                    	this.showPrev=true
	                    } else {
	                    	this.showPrev=false
	                    }
	                	 setTimeout(() => {
		                    loader.hide()
		                }, 1000)
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>
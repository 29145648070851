<template>
</template>

<script>
import axios from 'axios'
import config from '@/config.js';
import moment from 'moment';
import LocaleSwitcher from "@/components/LocaleSwitcher";

export default {
  name: "LayoutClass",
  components: {
	  LocaleSwitcher
  },
  data() {
    return {
      showMobileMenu: false,
      cart: {
        items: []
      },
      isAuthenticated:false,
      accessToken:"",
      refreshToken:"",
      copyright_year: moment(new Date()).format('YYYY'),
      site_name: "Mandala House",
      miscData: null,
    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')
    this.accessToken = JSON.parse(localStorage.getItem("accessToken"));
    
    const token = this.$store.state.token
    console.log(token)
    if (token) {
        //axios.defaults.headers.common['Authorization'] = "Token " + token
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
    } else {
        axios.defaults.headers.common['Authorization'] = ""
    }
  },
  mounted() {
  	this.isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));
    this.cart = this.$store.state.cart;
    this.miscData = JSON.parse(localStorage.getItem("miscData"));
   },
  methods: {
	async submitForm() {
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            //let msg = web3.utils.keccak256('Hello!');
            let public_address =  accounts[0];
            await axios.get("/api/metamask/"+public_address)
            .then(response => {
            	console.log(response.data.nonce) 	
            	this.login(response.data.nonce)
            	
            }).catch(error => {
            	this.signup()
            	console.log(error)
            })
    },
  	async getCoinbase() {
    	await axios
                .get("https://api.coinbase.com/v2/exchange-rates?currency=MATIC")
                .then(response => {
					this.$store.commit('setRate', response.data.data);
					console.log(response.data.data.currency);
					console.log(response.data.data.rates);
					console.log(response.data.data.rates['ETH']);
					console.log(response.data.data.rates['BTC']);
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    },
  	async logout() {
  			await axios
                .post("/api/v1/token/logout/", {"token":localStorage.getItem("token")})
                .then(response => {
                    
                    axios.defaults.headers.common["Authorization"] = ""
			
		            localStorage.removeItem("token")
		            localStorage.removeItem("username")
		            localStorage.removeItem("userid")
		
		            this.$store.commit('removeToken')
		            this.$store.state.user = ''
		            localStorage.removeItem("user")
		            this.$router.push('/')
		            this.$store.commit('showToast', 'Logout successfully..');
		            
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
        },
  },
  computed: {
  	toast() {
  			return this.$store.getters.toast;
  		},
      cartTotalLength() {
          let totalLength = 0

          for (let i = 0; i < this.cart.items.length; i++) {
              totalLength += this.cart.items[i].quantity
          }

          return totalLength
      }
  }
}
</script>

<template>
	<!--[if lt IE 9]>
		<div class="bg-danger text-center">You are using an <strong>outdated</strong> browser. Please <a href="http://browsehappy.com/" class="color-main">upgrade your browser</a> to improve your experience.</div>
	<![endif]-->

	<!-- search modal -->
	<div class="modal" tabindex="-1" role="dialog" aria-labelledby="search_modal" id="search_modal">
		<button type="button" class="close" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
		<div class="widget widget_search">
			<form method="get" class="searchform search-form" action="#">
				<div class="form-group">
					<input type="text" value="" name="search" class="form-control" placeholder="Search keyword" id="modal-search-input">
				</div>
				<button type="submit" class="btn">Search</button>
			</form>
		</div>
	</div>

	<!-- Unyson messages modal -->
	<div class="modal fade" tabindex="-1" role="dialog" id="messages_modal">
		<div class="fw-messages-wrap ls p-normal">
			<!-- Uncomment this UL with LI to show messages in modal popup to your user: -->
			<!--
		<ul class="list-unstyled">
			<li>Message To User</li>
		</ul>
		-->

		</div>
	</div><!-- eof .modal -->

	<div class="modal fade popupLogin" id="popupLogin" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content ds bs box-shadow bordered overflow-visible s-overlay s-mobile-overlay">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<div class="modal-body">
					<div class="container">
						<div class="row">
							<div class="col-12">
								<h4 class="color-main2 mb-3">Log in</h4>
								<form class="form-registration c-mb-20 c-gutter-20" action="">
									<div class="row mb-4">
										<div class="col-sm-12">
											<div class="form-group">
												<input type="text" name="name" class="form-control" required placeholder="login" aria-required="true">
											</div>
										</div>
										<div class="col-sm-12">
											<div class="form-group">
												<input type="password" name="password" class="form-control" placeholder="password" aria-required="true" required>
											</div>
										</div>
									</div>
									<a class="registerRedirect " data-dismiss="modal" data-target="#popupRegistr" data-toggle="modal" href="#">Not a member? Register!</a>
									<button type="submit" class="btn btn-maincolor mt-30 d-block">Sign In</button>
								</form>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade popupRegistr" id="popupRegistr" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content ds bs box-shadow bordered overflow-visible s-overlay s-mobile-overlay">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<div class="modal-body">
					<div class="container">
						<div class="row">
							<div class="col-12">
								<h4 class="color-main2 mb-4">Registration</h4>
								<form class="form-registration c-mb-20 c-gutter-20" method="post" action="https://html.modernwebtemplates.com/">
									<div class="row">
										<div class="col-12">
											<div class="form-group">
												<input type="text" name="name" class="form-control" required placeholder="login" aria-required="true">
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<input type="password" name="password" class="form-control" placeholder="password" aria-required="true" required>
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<input type="email" name="email" class="form-control" placeholder="email" required aria-required="true">
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<input type="password" name="confirmPassword" class="form-control" placeholder="password" required aria-required="true">
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<input type="checkbox" id="agreed" name="agreed" value="agreed" required aria-required="true"><label for="agreed">I agree to the rules</label>
											</div>
										</div>
									</div>
									<button type="submit" class="btn btn-maincolor mt-30 d-block">Registration</button>
								</form>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- wrappers for visual page editor and boxed version of template -->
	<div id="canvas">
		<div id="box_wrapper">

			<!-- template sections -->

			<div class="header_absolute ">

				<!--topline section visible only on small screens|-->
				<section class="page_topline ds s-py-30 ">
					<div class="container">
						<div class="row align-items-center">
							<div class="col-xl-6 col-lg-4 col-md-4 text-lg-left text-center">
								<a href="/" class="logo">
									<img src="../assets/images/logo.png" alt="img">
									<!-- span class="logo-text color-darkgrey">Kiến Nghiệp Thành Công</span-->
								</a>
							</div>
							<div class="col-xl-6 col-lg-8 col-md-8 some-links text-center text-lg-right d-flex justify-content-center justify-content-md-end align-items-center mt-2 mt-md-0">
								<a class="fs-24 links-maincolor-hover font-2" href="tel:+84854919891"><mark></mark> +84854919891</a>
								<a href="#faq" class="btn btn-outline-maincolor btn-medium  d-none d-md-block">
									Tra Cứu Thần Số Học
								</a>
							</div>
						</div>
					</div>
				</section>
				<!--eof topline-->

				<!-- header with two Bootstrap columns - left for logo and right for navigation and includes (search, social icons, additional links and buttons etc -->
				<header class="page_header ds ms s-overlay justify-nav-start">
					<div class="container">
						<div class="row align-items-center">
							<div class="col-xl-9 col-1 order-2 order-xl-1">
								<div class="nav-wrap">

									<!-- main nav start -->
									<nav class="top-nav">
										<ul class="nav sf-menu">
											<li class="active">
												<a href="/">Trang chủ</a>
											</li>
											<li>
												<a href="#">Thần Số Học</a>
												<div class="mega-menu">
													<ul class="mega-menu-row">
														<li class="mega-menu-col">
															<a href="#">Thần Số Học</a>
															<ul>
																<li>
																	<a href="/posts/than-so-hoc-so-1/">Thần Số Học Số 1</a>
																</li>
																<li>
																	<a href="/posts/than-so-hoc-so-2/">Thần Số Học Số 2</a>
																</li>
																<li>
																	<a href="/posts/than-so-hoc-so-3/">Thần Số Học Số 3</a>
																</li>
																<li>
																	<a href="/posts/than-so-hoc-so-4/">Thần Số Học Số 4</a>
																</li>
																<li>
																	<a href="/posts/than-so-hoc-so-5/">Thần Số Học Số 5</a>
																</li>
																<li>
																	<a href="/posts/than-so-hoc-so-6/">Thần Số Học Số 6</a>
																</li>
															</ul>
														</li>
														<li class="mega-menu-col">
															<a href="#">Thần Số Học</a>
															<ul>
																<li>
																	<a href="/posts/than-so-hoc-so-7/">Thần Số Học Số 7</a>
																</li>
																<li>
																	<a href="/posts/than-so-hoc-so-8/">Thần Số Học Số 8</a>
																</li>
																<li>
																	<a href="/posts/than-so-hoc-so-9/">Thần Số Học Số 9</a>
																</li>
																<li>
																	<a href="/posts/than-so-hoc-so-10/">Thần Số Học Số 10</a>
																</li>
																<li>
																	<a href="/posts/than-so-hoc-so-11/">Thần Số Học Số 11</a>
																</li>
																<li>
																	<a href="/posts/than-so-hoc-so-12/">Thần Số Học Số 12</a>
																</li>
															</ul>
														</li>
													</ul>
												</div> <!-- eof mega menu -->
											</li>
											<!-- eof features -->
											<li>
												<a href="/posts/type/handbook/">Cẩm Nang Huyền Học</a>
												<ul>
													<li><a href="/posts/type/zodiac-sign/">Cung Hoàng Đạo</a></li>
													<li><a href="/posts/type/feng-shui/">Phong Thủy</a></li>
													<li><a href="/posts/type/palmistry/">Sinh Trắc Vân Tay</a></li>
													<li><a href="/posts/type/tarot/">Tarot</a></li>
													<li><a href="/posts/type/numberology/">Thần Số Học</a></li>
												</ul>
											</li>
											<li>
												<a href="/pages/gioi-thieu/">Về Chúng Tôi</a>
											</li>
											<!-- eof pages -->

											<!-- contacts -->
											<li>
												<a href="/contact-us/">Liên Hệ</a>
											</li>
											<!-- eof contacts -->
										</ul>


									</nav>
									<!-- eof main nav -->

								</div>
							</div>
							<div class="col-xl-3 col-9 order-1 order-xl-2 text-xl-right text-left">
								<span class="social-icons">

									<a href="https://www.facebook.com/" class="fa fa-facebook " title="facebook"></a>
									<a href="https://twitter.com/" class="fa fa-twitter " title="twitter"></a>
									<a href="https://www.linkedin.com/" class="fa fa-linkedin " title="linkedin"></a>
									<a href="https://www.instagram.com/" class="fa fa-instagram " title="instagram"></a>
									<a href="https://www.youtube.com/" class="fa fa-youtube-play " title="youtube"></a>

								</span>
							</div>
						</div>
					</div>
					<!-- header toggler -->
					<span class="toggle_menu"><span></span></span>
				</header>

			</div>
	<router-view/> 
		<footer class="page_footer ds ms s-py-90 s-pb-lg-60 s-pt-xl-150 s-pb-xl-120">
				<div class="container">
					<div class="row align-center">
						<div class="col-xl-5 col-lg-5 text-center text-lg-left animate" data-animation="fadeInUp">
							<a href="/" class="logo">
								<img src="../assets/images/logo.png" alt="img">
								<!-- span class="logo-text color-darkgrey">Kiến Nghiệp Thành Công</span-->
							</a>
							<div class="divider-60 divider-lg-0"></div>
						</div>
						<div class="col-xl-2 col-lg-2 align-self-start text-center text-lg-left animate" data-animation="fadeInUp">
							<div class="widget widget_nav_menu">
								<h3>Dịch vụ</h3>
								<ul class="menu">
									<li class="menu-item">
										<a href="/posts/type/handbook/">Cẩm Nang Huyền Học</a>
									</li>
									<li class="menu-item"><a href="/posts/type/zodiac-sign/">Cung Hoàng Đạo</a></li>
									<li class="menu-item"><a href="/posts/type/feng-shui/">Phong Thủy</a></li>
									<li class="menu-item"><a href="/posts/type/palmistry/">Sinh Trắc Vân Tay</a></li>
									<li class="menu-item"><a href="/posts/type/tarot/">Tarot</a></li>
									<li class="menu-item"><a href="/posts/type/numberology/">Thần Số Học</a></li>
								</ul>
							</div>
							<div class="divider-60 divider-lg-0"></div>
						</div>
						<div class="col-xl-2 col-lg-2 align-self-start text-center text-lg-left animate" data-animation="fadeInUp">
							<div class="widget widget_nav_menu">
								<h3>Về Chúng Tôi</h3>
								<ul class="menu">
									<li class="menu-item">
										<a href="/pages/gioi-thieu/">Giới thiệu</a>
									</li>
									<li class="menu-item">
										<a href="/contact-us/">Liên hệ</a>
									</li>
								</ul>
							</div>
							<div class="divider-60 divider-lg-0"></div>
						</div>
						<div class="col-xl-3 col-lg-3 align-self-start text-center text-lg-left animate" data-animation="fadeInUp">
							<div class="widget widget_text">
								<h3>Liên hệ</h3>
								<p>
									72 DT766, Đức Hạnh, <br>
									Đức Linh, Bình Thuận
								</p>
								<p class="social-icons">
									<a href="https://www.facebook.com/" class="fa fa-facebook" title="facebook"></a>
									<a href="https://twitter.com/" class="fa fa-twitter" title="twitter"></a>
									<a href="https://www.instagram.com/" class="fa fa-instagram" title="instagram"></a>
									<a href="https://www.linkedin.com/" class="fa fa-linkedin" title="linkedin"></a>
									<a href="https://www.youtube.com/" class="fa fa-youtube-play" title="youtube"></a>
								</p>
							</div>
						</div>

					</div>
				</div>
			</footer>

			<section class="page_copyright ds ms s-py-25 s-bordertop-container">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-md-12 text-center">
							<p>&copy; Copyright <span class="copyright_year">{{copyright_year}}</span> All Rights Reserved</p>
						</div>
					</div>
				</div>
			</section>


		</div><!-- eof #box_wrapper -->
	</div><!-- eof #canvas -->
</template>

<script>
import DataAPI from '@/apis/data'
import axios from 'axios'
import config from '@/config.js';
import moment from 'moment';
import LocaleSwitcher from "@/components/LocaleSwitcher"
export default {
  name: "LayoutHome",
  components: {
  	LocaleSwitcher
  },
  data() {
    return {
    	language: localStorage.getItem('LOCALE'),
      	showMobileMenu: false,
      	isAuthenticated:false,
      	accessToken:"",
      	refreshToken:"",
      	copyright_year: moment(new Date()).format('YYYY'),
      	site_name: "FaceDet - Thần Số Học",
      	pages:null,
      	miscData:null,
    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')
    this.accessToken = JSON.parse(localStorage.getItem("accessToken"));
    
    const token = this.$store.state.token
    if (token) {
        //axios.defaults.headers.common['Authorization'] = "Token " + token
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
    } else {
        axios.defaults.headers.common['Authorization'] = ""
    }
    
  },
  mounted() {
  	this.isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));
   },
  methods: {
    	
	async submitForm() {
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            //let msg = web3.utils.keccak256('Hello!');
            let public_address =  accounts[0];
            await axios.get("/api/metamask/"+public_address)
            .then(response => {
            	console.log(response.data.nonce) 	
            	this.login(response.data.nonce)
            	
            }).catch(error => {
            	this.signup()
            	console.log(error)
            })
    },
    
  	async getCoinbase() {
    	await axios
                .get("https://api.coinbase.com/v2/exchange-rates?currency=MATIC")
                .then(response => {
					this.$store.commit('setRate', response.data.data);
					console.log(response.data.data.currency);
					console.log(response.data.data.rates);
					console.log(response.data.data.rates['ETH']);
					console.log(response.data.data.rates['BTC']);
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    },
  	async logout() {
  		console.log(localStorage.getItem("token"))
  		axios.defaults.headers.common["Authorization"] = "Token "+localStorage.getItem("token")
  		await axios
                .post("/api/v1/token/logout/", {})
                .then(response => {
		            localStorage.removeItem("token")
		            localStorage.removeItem("username")
		            localStorage.removeItem("userid")
		
		            this.$store.commit('removeToken')
		            this.$store.state.user = ''
		            localStorage.removeItem("user")
		            this.$router.push('/')
		            
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        console.log(JSON.stringify(error))
                    }
                })
        },
  },
  computed: {
  	toast() {
  			return this.$store.getters.toast;
  		},
      cartTotalLength() {
          let totalLength = 0
		   console.log(this.cart);
		   
          for (let i = 0; i < this.cart.items; i++) {
              totalLength += this.cart.items[i].quantity
          }

          return totalLength
      }
  }
}
</script>

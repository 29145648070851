export default {
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "Vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])},
  "Order Online Call Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Online Call Us"])},
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "Vietnamese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])},
    "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  },
  "Art Classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Classes"])},
  "Find our Mandala classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find our Mandala classes"])},
  "Art Gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Gallery"])},
  "Find our Mandala galleries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find our Mandala galleries"])},
  "Items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
  "Find Great Items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Great Items"])},
  "From our blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From our blog"])},
  "all my posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all my posts"])},
  "view all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view all"])},
  "All Rights Reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Rights Reserved"])},
  "FREE SHIPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FREE SHIPPING"])},
  "On all orders over $75.00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On all orders over $75.00"])},
  "FREE RETURNS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FREE RETURNS"])},
  "30 days money back guarantee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 days money back guarantee"])},
  "SECURE PAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECURE PAYMENT"])},
  "read more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["read more"])},
  "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "Blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Class List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class List"])},
  "More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More ..."])},
  "Place Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Order"])},
  "Pick up at Store or in your door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick up at Store or in your door"])},
  "Video list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video list"])},
  "Fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullname"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "Pay now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay now"])},
  "Shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
  "Online Gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Gallery"])},
  "Numerology loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numerology loading"])},
  "Numerology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numerology"])}
}